import React from 'react'
import { Link } from 'react-router-dom'

import logoWhite from '../../assets/images/logo_light_64.png'
import BackToHome from '../../components/back-to-home'
import background_img from '../../assets/images/items/board3_a.jpg'
import { UserService } from '../../services';
import { useState } from 'react';
import { store } from '../../state';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Signup() {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const srv = new UserService();
        const resp = await srv.createAccount(loginEmail, loginPassword);
        
        if (resp && resp > 0) {
            console.log(`Create Account resp is: ${resp}`);
            const user = await srv.getUser(resp);

            if (user && user.id === resp) {
                toast.success('User account created.');
                store.state.user.login(user);

                navigate('/home');
            } else {
                toast.error('Create user account may have failed.');
                navigate('/login');
            }
        }
    }

    return (
    <>
    <section className="position-relative">
    <div className="bg-full-page">
        <img src={background_img} alt="Cutting board, brick pattern."></img>
      </div>
      <div className="bg-overlay bg-linear-gradient-2"></div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-12 p-0">
                  <div className="d-flex flex-column min-vh-100 p-4">
                      <div className="text-center">
                          <Link to="/"><img src={logoWhite} alt=""/></Link>
                      </div>

                      <div className="title-heading text-center my-auto">
                          <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                              <form>
                                  <h5 className="mb-4">Register Your Account</h5>
                      
                                  <div className="row">
                                      <div className="col-lg-12">
                                          <div className="form-floating mb-2">
                                          <input 
                                                type="email" 
                                                className="form-control" 
                                                id="LoginEmail" 
                                                required 
                                                placeholder="name@example.com" 
                                                onChange={(e) => setLoginEmail(e.target.value)}/>
                                              <label htmlFor="LoginEmail">Email Address:</label>

                                          </div>
                                      </div>
                                      <div className={"warning-text " + (loginEmail === '' ? "" : "hidden")}>required</div>
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            required 
                                            id="LoginPassword" 
                                            placeholder="Password" 
                                            onChange={(e) => setLoginPassword(e.target.value)}/>
                                            <label htmlFor="LoginPassword">Password:</label>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            required 
                                            id="ConfirmPassword" 
                                            placeholder="Password" 
                                            onChange={(e) => setConfirmPassword(e.target.value)}/>
                                            <label htmlFor="ConfirmPassword">Confirm Password:</label>
                                        </div>
                                    </div>
                                  
                                    <div className={"warning-text " + (loginPassword !== confirmPassword ? "" : "hidden")}>Passwords must match</div>
                                
                                    <div className="col-lg-12">
                                        <div className="form-check align-items-center d-flex mb-3">
                                            <input 
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                value=""
                                                id="AcceptT&C"
                                                onChange={(e) => {setTermsAccepted(!termsAccepted)}}/>
                                            <label className="form-check-label text-muted ms-2" htmlFor="AcceptT&C">I Accept <Link to="#" className="text-primary">Terms And Condition</Link></label>
                                        </div>
                                    </div>
                    
                                    <div className="col-lg-12">
                                        <button 
                                        className="btn btn-primary rounded-md w-100" 
                                        type="button" 
                                        disabled={loginPassword !== confirmPassword || loginEmail === '' || !termsAccepted}
                                        onClick={handleSubmit}>Register</button>
                                    </div>

                                    <div className="col-12 text-center mt-4">
                                        <small><span className="text-muted me-2">Already have an account ? </span> <Link to="/login" className="text-dark fw-bold">Sign in</Link></small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="text-center">
                        <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <BackToHome/>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />           
   </>
  )
}
