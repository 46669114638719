import React from 'react'
import { Link } from 'react-router-dom'

import logoWhite from '../../assets/images/logo_light_64.png'
import background_img from '../../assets/images/items/board3_a.jpg'
import BackToHome from '../../components/back-to-home'
import { store } from '../../state';
import { UserService } from '../../services';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login({navigation}) {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginFailed, setLoginFailed] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        let user = null;
        const srv = new UserService();
        toast.info('Attempting login ...');
        const resp = await srv.login(loginEmail, loginPassword);
        toast.info(`Login resp: ${JSON.stringify(resp)}`);
        if (resp.error) {
            toast.error(`Login failed with: ${resp.error}`);
        }

        if (resp.status === 201 && resp.data > 0) {
            const redirectTo = store.state.session.getRedirectTo();
            
            if (redirectTo) {
                store.state.session.setRedirectTo(null); 

                navigate(redirectTo);
            } else {
                navigate('/home');
            }
        } else {
            setLoginFailed(true);
        }
    }

    return (
    <>
    <section className="position-relative">
      <div className="bg-full-page">
        <img src={background_img} alt="Cutting board, brick pattern."></img>
      </div>
      <div className="bg-overlay bg-linear-gradient-2"></div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-12 p-0">
                  <div className="d-flex flex-column min-vh-100 p-4">
                      <div className="text-center">
                          <Link to="/"><img src={logoWhite} alt=""/></Link>
                      </div>

                      <div className="title-heading text-center my-auto">
                          <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                              <form onSubmit={handleSubmit}>
                                  <h5 className="mb-4">Login</h5>
                                  <div className="row">
                                      <div className="col-lg-12">
                                          <div className="form-floating mb-2">
                                              <input 
                                                type="email" 
                                                className="form-control" 
                                                id="LoginEmail" 
                                                required 
                                                placeholder="name@example.com" 
                                                onChange={(e) => setLoginEmail(e.target.value)}/>
                                              <label htmlFor="LoginEmail">Email Address:</label>
                                          </div>
                                      </div>

                                      <div className="col-lg-12">
                                          <div className="form-floating mb-3">
                                              <input 
                                                type="password" 
                                                className="form-control" 
                                                required 
                                                id="LoginPassword" 
                                                placeholder="Password" 
                                                onChange={(e) => setLoginPassword(e.target.value)}/>
                                              <label htmlFor="LoginPassword">Password:</label>
                                          </div>
                                      </div>
                              
                                      <div className="col-lg-12">
                                          <div className="d-flex justify-content-between">
                                              <div className="mb-3">
                                                  <div className="form-check align-items-center d-flex mb-0">
                                                      <input className="form-check-input mt-0" type="checkbox" value="" id="RememberMe"/>
                                                      <label className="form-check-label text-muted ms-2" htmlFor="RememberMe">Remember me</label>
                                                  </div>
                                              </div>
                                              <small className="text-muted mb-0"><Link to="/reset-password" className="text-muted fw-semibold">Forgot password ?</Link></small>
                                          </div>
                                      </div>
                  
                                      <div className="col-lg-12">
                                          <button 
                                            className="btn btn-primary rounded-md w-100" 
                                            type="button" 
                                            onClick={handleSubmit}>Sign in</button>
                                      </div>
  
                                      <div className={"warning-text " + (loginFailed ? "" : "hidden")}>Username or password was incorrect</div>
                                            
                                      <div className="col-12 text-center mt-4">
                                          <small><span className="text-muted me-2">Don't have an account ?</span> <Link to="/signup" className="text-dark fw-bold">Sign Up</Link></small>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>

                      <div className="text-center">
                      <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <BackToHome/>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}
