import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import logoWhite from '../../assets/images/logo_light_64.png'
import background_img from '../../assets/images/items/board3_a.jpg'
import BackToHome from '../../components/back-to-home'
import { store } from '../../state';
import { UserService, ApplicationsService } from '../../services';
import { useNavigate } from "react-router-dom";
import defaultUserImg from '../../assets/images/default_user.gif';
import AppConfig from '../../App.config';

const appSrv = new ApplicationsService();

export default function ChangePassword({navigation}) {
    
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [userProfileImg, setUserProfileImg] = useState(defaultUserImg);

    const navigate = useNavigate();
    useEffect(()=>{
        //const creatorId = searchParams.get("creatorId");

        const getUserProfileImage = async (userId) => {
            const upi = await appSrv.getApplicationFile(`user_${userId}`, AppConfig.appVars.userProfileImg);

            if (upi) {
                setUserProfileImg(upi);
            }
        }
        
        getUserProfileImage(store.state.user.userId);
    }, []);

    const handleSubmit = async () => {
        const srv = new UserService();
        const userId = store.state.user.id;
        const resp = await srv.changePassword(userId, oldPassword, newPassword);
        
        if (resp) {
            console.log(`Change Password resp is: ${resp}`);
            navigate('/');
        }
    }
    function handleProfileImageChange(e) {
        console.log(e.target.files[0]);
        setUserProfileImg(URL.createObjectURL(e.target.files[0]));
    }
    return (
    <>
    <section className="position-relative">
      <div className="bg-full-page">
        <img src={background_img} alt="Cutting board, brick pattern."></img>
      </div>
      <div className="bg-overlay bg-linear-gradient-2"></div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-12 p-0">
                  <div className="d-flex flex-column min-vh-100 p-4">
                      <div className="text-center">
                          <Link to="/"><img src={logoWhite} alt=""/></Link>
                      </div>

                      <div className="title-heading text-center my-auto">
                          <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                              <form onSubmit={handleSubmit}>
                                <h5 className="mb-4">User Profile</h5>

                                    <div className="row">
                                        <div className="col-lg-12 mt-4 pt-2">
                                            <div className="card ms-lg-5">
                                                <div className="profile-pic">
                                                    <input id="pro-img" name="profile-image" type="file" className="d-none" onChange={(e)=>handleProfileImageChange(e)} />
                                                    <div className="position-relative d-inline-block">
                                                        <img src={userProfileImg} className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt=""/>
                                                        <label className="icons position-absolute bottom-0 end-0" htmlFor="pro-img"><span className="btn btn-icon btn-sm btn-pills btn-primary"><i className="uil uil-camera fs-6"></i></span></label>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <p className="text-muted mb-0">We recommend an image of at least 400X400. GIFs work too.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12">
                                          <div className="form-floating mb-3">
                                              <input 
                                                type="password" 
                                                className="form-control" 
                                                required 
                                                id="OldPassword" 
                                                placeholder="Old Password" 
                                                onChange={(e) => setOldPassword(e.target.value)}/>
                                              <label htmlFor="OldPassword">Old Password:</label>
                                          </div>
                                      </div>

                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input 
                                                type="password" 
                                                className="form-control" 
                                                required 
                                                id="NewPassword" 
                                                placeholder="New Password" 
                                                onChange={(e) => setNewPassword(e.target.value)}/>
                                                <label htmlFor="NewPassword">New Password:</label>
                                            </div>
                                        </div>
                              
                                      <div className="col-lg-12">
                                          <div className="d-flex justify-content-between">
                                              <div className="mb-3">
                                                  <div className="form-check align-items-center d-flex mb-0">
                                                      <input className="form-check-input mt-0" type="checkbox" value="" id="RememberMe"/>
                                                      <label className="form-check-label text-muted ms-2" for="RememberMe">Remember me</label>
                                                  </div>
                                              </div>
                                              <small className="text-muted mb-0"><Link to="/reset-password" className="text-muted fw-semibold">Forgot password ?</Link></small>
                                          </div>
                                      </div>
                  
                                      <div className="col-lg-12">
                                          <button 
                                            className="btn btn-primary rounded-md w-100" 
                                            type="button" 
                                            onClick={handleSubmit}>Sign in</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>

                      <div className="text-center">
                          <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <BackToHome/>
    </>
  )
}
