import React,{useState} from 'react'
import { Link } from 'react-router-dom'

import appImg from '../assets/images/app.png'
import playstore from '../assets/images/playstore.png'
import logo  from '../assets/images/logo_64g.png'

export default function Footer() {
    let [visible, setVisible] = useState(false)

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop; 
        if (scrolled > 300){ 
          setVisible(true) 
        }  
        else if (scrolled <= 300){ 
          setVisible(false) 
        } 
      }; 
      
      const scrollToTop = () =>{ 
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
      }; 
      
      window.addEventListener('scroll', toggleVisible); 
  return (
    <>
    <footer className="bg-footer">

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-py-60 footer-border">
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <Link to="#" className="logo-footer">
                                    <img src={logo} alt=""/>
                                </Link>
                                <p className="para-desc mb-0 mt-4">Display, sell, buy, and discover unique, hand made works of art.</p>
                            
                                
                            </div>
                            
                            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Created By Me</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="/explore-two" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Explore</Link></li>
                                    <li className='ms-0'><Link to="/auction" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Auctions</Link></li>
                                    <li className='ms-0'><Link to="/creators" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Creators</Link></li>
                                </ul>
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Community</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="/aboutus" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> About Us</Link></li>
                                    <li className='ms-0'><Link to="/terms" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Terms & Conditions</Link></li>
                                    <li className='ms-0'><Link to="/privacy" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Privacy Policy</Link></li>
                                    <li className='ms-0'><Link to="/login" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Login</Link></li>
                                    <li className='ms-0'><Link to="/contact" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Contact</Link></li>
                                </ul>
                            </div>
        
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Newsletter</h5>
                                <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="foot-subscribe mb-3">
                                                <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-envelope icons fs-5 mb-2"></i>
                                                    <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-grid">
                                            <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-py-30 footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-start">
                            <p className="mb-0">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <ul className="list-unstyled footer-list text-sm-end mb-0">
                            <li className="list-inline-item mb-0"><Link to="/privacy" className="text-foot me-2">Privacy</Link></li>
                            <li className="list-inline-item mb-0"><Link to="/terms" className="text-foot me-2">Terms</Link></li>
                            <li className="list-inline-item mb-0"><Link to="/helpcenter-overview" className="text-foot me-2">Help Center</Link></li>
                            <li className="list-inline-item mb-0"><Link to="/contact" className="text-foot">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <Link to="#" id="back-to-top" onClick={()=>scrollToTop()} className="back-to-top rounded-pill fs-5" style={{display: visible ? 'inline' : 'none'}}><i className="uil uil-arrow-up"></i></Link>
    </>
  )
}
