import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from "../../components/footer";
import cbg from '../../assets/images/work/1.jpg';
import {ApplicationsService, AssetService, CreatorService} from '../../services';
import { SocialMediaHelper } from '../../helpers';
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import defaultUserImg from '../../assets/images/default_user.gif';
import defaultAssetImg from '../../assets/images/svg/office-desk.svg';
import AppConfig from '../../App.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();
const assetSrv = new AssetService();

export default function CreatorProfile() {
    const [creatorData, setCreatorData] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [websiteData, setWebsiteData] = useState(null);
    const [mediaData, setMediaData] = useState(null);
    const [userProfileImg, setUserProfileImg] = useState(defaultUserImg);
    const [creatorBgImg, setCreatorBgImg] = useState(cbg);
    const [ assetData, setAssetData ] = useState(null);
    const [ assetMetadata, setAssetMetadata ] = useState(null);
    const [ cursor, setCursor ] = useState(null);
  
    const { creatorId } = useParams();
  
    useEffect(()=>{
        const updateCreatorRecord = (rec) => {
            const data = rec.links.filter(l => {
                l.className = SocialMediaHelper.getSocialMediaClass(l.mediaName);
                if (l.mediaName === 'website') {
                    setWebsiteData(l);
                } else {
                    return l;
                }
            });
            setMediaData(data);
            setCreatorData(rec);
            //getUserProfileImage(store.state.user.getId());
        }

        const getCreatorRecord = async (id) => {
            if (id) {
                const promises = [];
                const regex = /^(0x)?[0-9a-fA-F]{40}$/;
                if (regex.test(id)) {
                    await creatorSrv.getCreator(id).then(rec => {
                        updateCreatorRecord(rec);
                        getAssetData(rec.creatorId);
                        promises.push(appSrv.getApplicationFile(rec.creatorId, AppConfig.appVars.creatorBackground)
                        .then(d => {
                            if (d !== null) {
                                setCreatorBgImg(d);
                            }
                        })
                        .catch(() => {
                            if (store.state.user.loggedIn && rec.creatorId === store.state.user.getCreatorId()) {
                                toast.info('Your personal background was not found or is not set.');
                            } else {
                                console.log ('Creator background not found, using default');
                            }
                            
                        }));
                        promises.push(appSrv.getApplicationFile(rec.creatorId, AppConfig.appVars.creatorProfileImg)
                        .then(d => {
                            if (d !== null) {
                                setUserProfileImg(d)
                            }
                        })
                        .catch(() => {
                            if (store.state.user.loggedIn && rec.creatorId === store.state.user.getCreatorId()) {
                                toast.info('Your creator profile image was not found or is not set.');
                            } else {
                                console.log ('Creator profile image not found, using default');
                            }
                            
                        }));
    
                    });
                } else {
                    await creatorSrv.getCreatorByName(id).then(rec => {
                        updateCreatorRecord(rec);
                        getAssetData(rec.creatorId);
                        promises.push(appSrv.getApplicationFile(rec.creatorId, AppConfig.appVars.creatorCardImg)
                        .then(d => {
                            if (d !== null) {
                                setCreatorBgImg(d);
                            }
                        })
                        .catch(() => {
                            if (store.state.user.loggedIn && rec.creatorId === store.state.user.getCreatorId()) {
                                toast.info('Your personal background was not found or is not set.');
                            } else {
                                console.log ('Creator background not found, using default');
                            }
                            
                        }));
                        promises.push(appSrv.getApplicationFile(rec.creatorId, AppConfig.appVars.creatorProfileImg)
                        .then(d => {
                            if (d !== null) {
                                setUserProfileImg(d)
                            }
                        })
                        .catch(() => {
                            if (store.state.user.loggedIn && rec.creatorId === store.state.user.getCreatorId()) {
                                toast.info('Your creator profile image was not found or is not set.');
                            } else {
                                console.log ('Creator profile image not found, using default');
                            }
                            
                        }));                    });
                }
            }
        };

        const getAssetData = async (cid) => {
            const data = await assetSrv.getAssetsForCreator(cid, 25, null, null, null);
            setAssetData(data);
    
            if (data && data.assets) {
                    
                const metadataPromises = data.assets.map (ad => {
                    ad.url = ad.url.replace('http://192.168.4.148', 'https://createdbyme.io');
                    return appSrv.getRemoteFile(ad.url)
                        .then(md => {
                            return {aid: ad.assetId, data: md};
                        });
                });

                await Promise.all(metadataPromises).then(resp => {
                    let md = {};
                    
                    for (let i = 0; i < resp.length; i++) {
                        resp[i].data.image = resp[i].data.image.replace('http://192.168.4.148', 'https://createdbyme.io');
                        Object.defineProperty(md, `_${resp[i].aid}`, {value:resp[i].data});
                    }
                    
                    setAssetMetadata(md);
                });
            }
        }

        getCreatorRecord(creatorId);        

        /*
        if (store.state.user.loggedIn) {
            getUserProfileImage(store.state.user.getId());
        }
            */
    }, []);

    /*
    const getCreatorBgImage = async (id = null) => {
        let upi = null;

        if (id) {
            upi = await appSrv.getApplicationFile(id, AppConfig.appVars.creatorBackground);
        }

        if (upi) {
            setCreatorBgImg(upi);
        }
    }

    const getUserProfileImage = async (userId) => {
        let upi = null;

        if (creatorData) {
            upi = await appSrv.getApplicationFile(creatorData.creatorId, AppConfig.appVars.creatorProfileImg);
        }

        if (!upi) {
            upi = await appSrv.getApplicationFile(`user_${userId}`, AppConfig.appVars.userProfileImg);
        }

        if (upi) {
            setUserProfileImg(upi);
        }
    }
*/
    return (
    <>
    <Navbar/>
      <section className="bg-creator-profile">
        <div className="container">
            <div className="profile-banner">
                <div className="position-relative d-inline-block">
                    <img src={creatorBgImg} className="rounded-md shadow-sm img-fluid" id="profile-banner" alt=""/>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center mt-n80">
                        <div className="profile-pic">
                            <div className="position-relative d-inline-block">
                                <img src={userProfileImg} className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt=""/>
                            </div>
                        </div>

                        <div className="content mt-3">
                            <h5 className="mb-3">{creatorData?.displayName}</h5>
                            
                            <h6 className="mt-3 mb-0">{creatorData?.story}</h6>
                            {websiteData && (
                                <h6 className="mt-3 mb-0">
                                    <Link to={websiteData.mediaUrl} >
                                        Vist my website
                                        <i className={websiteData.className}></i>
                                    </Link>
                                </h6>
                            )}
                            <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4 ">    
                             {mediaData?.map((l, index) => {
                                return (
                                    <li className="list-inline-item lh-1" key={`media_link_${l.mediaName}_${index}` }>
                                        <Link to={l.mediaUrl} className="rounded">
                                            <i className={l.className}></i>
                                        </Link>
                                    </li>
                                        
                                );
                            })}
                            </ul>
                            {(store.state.user.getCreatorId() === creatorData?.creatorId) &&
                            <>
                                <div className="mt-4">
                                    <Link to={`/creator-profile-edit/${store.state.user.getCreatorId()}`} className="btn btn-pills btn-outline-primary mx-1">Edit Profile</Link>
                                </div>
                                <div className="mt-4">
                                    <Link to={`/register-work`} className="btn btn-pills btn-outline-primary mx-1">Register Work</Link>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="container">
            <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              {assetData?.assets.map((item,index)=>{
                return(
                    <div className="col mt-4 pt-2" key={index}>
                        <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex justify-content-between">
                            <div className="img-group">
                                <Link to="/creator-profile" className="user-avatar">
                                    {userProfileImg !== null && (
                                    <img src={userProfileImg} alt="creator" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                    )}
                                    {userProfileImg === null  && (
                                    <img src={defaultUserImg} alt="" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                    )}
                                </Link>
                            </div>
                            
                            <span className="like-icon shadow-sm"><Link to="#" className="text-muted icon"><i className="mdi mdi-18px mdi-heart mb-0"></i></Link></span>
                        </div>

                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                            {(assetMetadata && assetMetadata[`_${item.assetId}`] !== null) && (
                            <Link to={`/work/${item.assetId}`}><img src={assetMetadata[`_${item.assetId}`].image} className="img-fluid" alt=""/></Link>
                            )}
                            {(assetMetadata && assetMetadata[`_${item.assetId}`] === null) && (
                            <Link to={`/work/${item.assetId}`}><img src={defaultAssetImg} className="img-fluid" alt=""/></Link>
                            )}
                            <div className="position-absolute top-0 start-0 m-2">
                                <Link to="#" className="badge badge-link bg-primary">{item.tag}</Link>
                            </div>

                            <div className="position-absolute top-0 end-0 m-2">
                                <Link to={`/work/${item.assetId}`} className="btn btn-pills btn-icon"><i className="uil uil-shopping-cart-alt"></i></Link>
                            </div>
                        </div>

                        <div className="card-body content position-relative p-0 mt-3">
                            <Link to={`/work/${item.assetId}`} className="title text-dark h6">{item.name}</Link>

                            <div className="d-flex justify-content-between mt-2">
                                
                                <small className="rate fw-bold">
                                {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.value) && (
                                    <>
                                    {assetMetadata[`_${item.assetId}`].properties?.value} {assetMetadata[`_${item.assetId}`].properties?.currency}
                                    </>
                                )}
                                </small>
                                
                                <small className="text-dark fw-bold">
                                {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.copyId) && (
                                    <>
                                    {assetMetadata[`_${item.assetId}`].properties.copyId} out of {assetMetadata[`_${item.assetId}`].properties.numberOfCopies}
                                    </>
                                )}
                                </small>
                                
                                
                            </div>
                        </div>
                        </div>
                    </div>
                )
              })}
            </div>

            <div className="row justify-content-center mt-4">
                <div className="col">
                    <div className="text-center">
                        <Link to="#" className="btn btn-primary rounded-md"><i className="uil uil-process mdi-spin me-1"></i> Load More</Link>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Footer/>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}
