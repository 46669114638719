import React from 'react'
import { Link } from 'react-router-dom'
import logoWhite from '../../assets/images/logo_light_64.png'
import BackToHome from '../../components/back-to-home'
import background_img from '../../assets/images/items/board3_a.jpg';
import { store, userLogin } from '../../state';
import { UserService } from '../../services';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const srv = new UserService();
        const userId = store.state.user.id;
        const resp = await srv.changePassword(userId, oldPassword, newPassword);
        
        if (resp) {
            console.log(`Change Password resp is: ${resp}`);
            navigate('/');
        }
    }
  
    return (
    <>
    <section className="position-relative">
    <div className="bg-full-page">
        <img src={background_img} alt="Cutting board, brick pattern."></img>
      </div>
      <div className="bg-overlay bg-linear-gradient-2"></div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-12 p-0">
                  <div className="d-flex flex-column min-vh-100 p-4">
                      <div className="text-center">
                          <Link to="/"><img src={logoWhite} alt=""/></Link>
                      </div>

                      <div className="title-heading text-center my-auto">
                          <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                              <form>
                                  <h5 className="mb-3">Reset Your Password</h5>

                                  <p className="text-muted">Please enter your email address. You will receive a link to create a new password via email.</p>
                              
                                  <div className="row">
                                      <div className="col-12">
                                          <div className="form-floating mb-3">
                                              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" required/>
                                              <label htmlFor="floatingInput">Email address</label>
                                          </div>
                                      </div>
                      
                                      <div className="col-12">
                                          <button className="btn btn-primary rounded-md w-100" type="submit">Send</button>
                                      </div>

                                      <div className="col-12 text-center mt-4">
                                          <small><span className="text-muted me-2">Remember your password ? </span><Link to="/login" className="text-dark fw-bold">Sign in</Link></small>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>

                      <div className="text-center">
                      <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <BackToHome/>
    </>
  )
}
