import React from 'react'
import { Link } from 'react-router-dom'

import BackToHome from '../../components/back-to-home'

import bg1 from '../../assets/images/bg/carpenter_1.png'
import logoLight from '../../assets/images/logo_light_64.png'
import RoadMap from '../../components/road-map';

export default function CurrentRoadMap () {
  return (
    <>
      <section className="position-relative zoom-image">
        <div className="bg-overlay image-wrap" style={{backgroundImage:`url("${bg1}")` , backgroundPosition:'bottom'}}></div>
        <div className="bg-overlay"></div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="d-flex flex-column min-vh-100 p-4">
                        <div className="text-center">
                            <Link to="/"><img src={logoLight} alt=""/></Link>
                        </div>

                        <div className="title-heading text-center my-auto">
                            <h4 className="coming-soon fw-bold display-5 text-white title-dark text-uppercase mt-5">Road Map</h4>
                            <p className="text-white title-dark para-desc mx-auto mb-0">
                                This is the current road map of the Created by Me application. This road map is a planning document and is subject to change.
                            </p>
        
                            <div className="row justify-content-center mt-5">
                                <div className="col-xl-10 mt-lg-5">
                                    < RoadMap />
                                </div>
                            </div>
        
                        </div>

                        <div className="text-center mt-5">
                            <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BackToHome/>
    </>
  )
}
