import { BaseService } from './base.service';
import AppConfig from '../App.config';

export class AssetService extends BaseService {

    async registerAsset (
        creatorId,
        assetId,
        name,
        description,
        seriesId = null,
        ownerAddress = null,
        metadataUrl = null,
        documentHash = null,
        tags = null) {
        const url = `${AppConfig.assetApi}`;

        const body = {
            "creatorId": creatorId,
            "assetId": assetId,
            "name": name,
            "description": description,
            "seriesId": seriesId,
            "ownerAddres": ownerAddress,
            "url": metadataUrl,
            "documentHash": documentHash,
            "tags": tags
        };

        const resp = await this.executePost(url, body);

        return resp;
    }

    async updateAsset (
        creatorId,
        assetId,
        name,
        description,
        seriesId = null,
        ownerAddress = null,
        metadataUrl = null,
        documentHash = null,
        tags = null) {
        const url = `${AppConfig.assetApi}/update`;

        const body = {
            "creatorId": creatorId,
            "assetId": assetId,
            "name": name,
            "description": description,
            "seriesId": seriesId,
            "ownerAddres": ownerAddress,
            "url": metadataUrl,
            "documentHash": documentHash,
            "tags": tags
        };

        const resp = await this.executePost(url, body);

        return resp;
    }

    async getAssetList (pageSize, startKey = null, endKey = null, cursor = null) {
        const url = `${AppConfig.assetApi}`;

        let queryParams = {};
        if (pageSize) queryParams['pageSize'] = pageSize;
        if (startKey) queryParams['startKey'] = startKey;
        if (endKey) queryParams['endKey'] = endKey;
        if (cursor) queryParams['cursor'] = cursor;

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get newest returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getNewest (count, creatorId = null) {
        const url = `${AppConfig.assetApi}/newest`;

        let queryParams = {
            'count': count,
        };
        if (creatorId) queryParams['creatorId'] = creatorId;
console.log(`getNewest: ${url}, params: ${JSON.stringify(queryParams)}`);
        const resp = await this.executeGet(url, queryParams);
        console.log(`Get asset list returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getAsset (assetId) {
        const url = `${AppConfig.assetApi}/byAssetId/${assetId}`;

        const resp = await this.executeGet(url, {});
        console.log(`Get asset returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getAssetsForCreator (creatorId, pageSize, startKey = null, endKey = null, cursor = null) {
        const url = `${AppConfig.assetApi}/getByCreatorId/${creatorId}`;

        let queryParams = {};
        if (pageSize) queryParams['pageSize'] = pageSize;
        if (startKey) queryParams['startKey'] = startKey;
        if (endKey) queryParams['endKey'] = endKey;
        if (cursor) queryParams['cursor'] = cursor;

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get asset list for creator returned: ${JSON.stringify(resp)}`);
        return resp.data;
    }

    async getAssetsWithTags (tagSet, pageSize, cursor = null) {
        const url = `${AppConfig.assetApi}/getWithTags/${tagSet}`;

        let queryParams = {};
        if (pageSize) queryParams['pageSize'] = pageSize;
        if (cursor) queryParams['cursor'] = cursor;

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get assets with tags returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getAssetsByTags (tagSet, pageSize, cursor = null) {
        const url = `${AppConfig.assetApi}/getByTags/${tagSet}`;

        let queryParams = {};
        if (pageSize) queryParams['pageSize'] = pageSize;
        if (cursor) queryParams['cursor'] = cursor;

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get assets by tags returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }


    async getMatchingTags (partialTag) {
        const url = `${AppConfig.assetApi}/getMatchingTags/${partialTag}`;

        const resp = await this.executeGet(url, {});
        console.log(`Get tags returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }
    
    async addTagToAsset (creatorId, assetId, tag) {
        const url = `${AppConfig.assetApi}/addTag`;

        const body = {
            "creatorId": creatorId,
            "assetId": assetId,
            "tag": tag
        };

        const resp = await this.executePost(url, body);

        return resp.data;
    }

    async removeTagFromAsset (creatorId, assetId, tag) {
        const url = `${AppConfig.assetApi}/removeTag`;

        const body = {
            "creatorId": creatorId,
            "assetId": assetId,
            "tag": tag
        };

        const resp = await this.executePost(url, body);

        return resp.data;
    }
    
    async addQid(qidDto) {
        const url = `${AppConfig.assetApi}/addQid`;

        console.log(`calling ${url} for ${JSON.stringify(qidDto)}`);
        const resp = await this.executePost(url, qidDto);
        console.log(`addQid returned: ${JSON.stringify(resp)}`);
        return resp.data;
    }

    async removeQid(assetId, qid) {
        const url = `${AppConfig.assetApi}/removeQid`;

        const body = {
            "assetId": assetId,
            "qid": qid,
            "label": null,
            "description": null
        };
        console.log(`calling ${url} for ${JSON.stringify(body)}`);
        const resp = await this.executeDelete(url, body);
        console.log(`removeQid returned: ${JSON.stringify(resp)}`);
        return resp.data;
    }

    async hashDocument(doc) {
        const url = `${AppConfig.assetApi}/hash`;

        const body = {
            "data": doc,
        };
        console.log(`calling ${url} for ${JSON.stringify(body)}`);
        const resp = await this.executePost(url, body);
        console.log(`hashDocument returned: ${JSON.stringify(resp)}`);
        return resp;
    }
}