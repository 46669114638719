export class AppStorage {
    name;
    data = {};

    constructor (className) {
        this.name = className;
    }
    store () {
        localStorage.setItem(this.name, JSON.stringify(this.data));
    }

    load () {
        const d =localStorage.getItem(this.name)

        if(d) {
            this.data = JSON.parse(d);
        }
    }
}