import React from 'react';
import WorkLink from './work-link';
import WorkImage from './work-image';
import WorkVideo from './work-video';
import WorkDocument from './work-document';

export default function WorkProperty({
    propKey,
    propValue,
    options=null
}) {
    const keys = Object.getOwnPropertyNames(propValue);
    console.log(`AssetProperty keys: ${keys}`);
   
    if (keys.indexOf('type') >= 0) {
        if (propValue['type'] === 'IMAGE') {
            return (
                <WorkImage url={propValue['url']} 
                    title={propValue['title']}
                    alt={propValue['alt']}
                    options={options}></WorkImage>
            );
        } else if (propValue['type'] === 'LINK') {
            return (
                <WorkLink url={propValue['url']} title={propValue['title']}></WorkLink>
            )
        } else if (propValue['type'] === 'DOCUMENT') {
            return (
                <WorkDocument url={propValue['url']} title={propValue['title']}></WorkDocument>
            )
        } else {
            return (
                <div className="d-flex align-items-start">
                    <div>{`${propValue['type']}: `}</div>
                    <div>{propValue['value']}</div>
                </div>
            );
        }
    } else {
        
        
        return (
            <div className="d-flex align-items-start">
                <div>{`${propKey}: `}</div>
                <div>{propValue}</div>
            </div>
        );
    }
    
}