import { BaseService } from './base.service';
import AppConfig from '../App.config';

export class CreatorService extends BaseService {
    async getCreator(creatorId){
        const url = `${AppConfig.creatorApi}/${creatorId}`;

        const resp = await this.executeGet(url, {});

        console.log(`Get creator returned: ${JSON.stringify(resp)}`)

        return resp.data;
    } 

    async updateCreatorStory(creatorId, story) {
        const url = `${AppConfig.creatorApi}/updateCreatorStory`;
        const body = {
            'creatorId': creatorId,
            'story': story
        };

        const resp = await this.executePost(url, body);

        return resp;
    }

    async getCreatorByName(creatorName) {
        const url = `${AppConfig.creatorApi}/findByDisplayName/${creatorName}`;

        const resp = await this.executeGet(url, {});

        console.log(`Get creator by name returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async creatorNameLookup(creatorName) {
        const url = `${AppConfig.creatorApi}/displayNameLookup/${creatorName}`;

        const resp = await this.executeGet(url, {});

        console.log(`Lookup creators by name returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getCreators(pageSize, startKey = null, endKey = null, cursor = null) {
        let url = `${AppConfig.creatorApi}/getCreators/${pageSize}`;
        let queryParams = {};
        if (startKey) queryParams['startKey'] = startKey;
        if (endKey) queryParams['endKey'] = endKey;
        if (cursor) queryParams['cursor'] = cursor;

        console.log(`Calling ${url}`);
        const resp = await this.executeGet(url, queryParams);

        console.log(`Get creators returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async isNameAvailable(creatorName) {
        const url = `${AppConfig.creatorApi}/isCreatorNameAvailable/${creatorName}`;

        const resp = await this.executeGet(url, {});

        console.log(`Is name available returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async registerCreator(userId, displayName, story) {
        const nameAvailable = await this.isNameAvailable(displayName);

        if (!nameAvailable) return false;
        
        const url = `${AppConfig.creatorApi}`;

        const body = {
            "userId": userId,
            "displayName": displayName,
            "story": story
        };

        console.log(`registerCreator calling ${url} for ${body}`);
        const resp = await this.executePost(url, body);
        console.log(`registerCreator returned ${JSON.stringify(resp)}`);

        return resp.data;
    }

    async createSeries(creatorId, seriesId, descr) {
        const url = AppConfig.creatorSeriesApi;

        const body = {
            "creatorId": creatorId,
            "seriesId": seriesId,
            "description": descr
        };

        const resp = await this.executePost(url, body);

        return resp.data;
    }

    async getSeriesList(creatorId, pageSize, startKey = null, endKey = null, cursor = null) {
        let url = `${AppConfig.creatorSeriesApi}/${creatorId}`;
        let queryParams = {};
        if (pageSize) queryParams['pageSize'] = pageSize;
        if (startKey) queryParams['startKey'] = startKey;
        if (endKey) queryParams['endKey'] = endKey;
        if (cursor) queryParams['cursor'] = cursor;

        const resp = await this.executeGet(url, queryParams);

        console.log(`Get series list returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getSeriesDetail(creatorId, seriesId) {
        let url = `${AppConfig.creatorSeriesApi}/${creatorId}/${seriesId}`;
        let queryParams = {};
        
        const resp = await this.executeGet(url, queryParams);

        console.log(`Get series detail returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async addSocialMediaLink(creatorId, mediaName, link) {
        const url = `${AppConfig.creatorApi}/addSocialMediaLink`;

        const body = {
            "creatorId": creatorId,
            "mediaName": mediaName,
            "link": link
        };

        console.log(`addSocialMediaLink ${url} called for ${JSON.stringify(body)}`);
        const resp = await this.executePost(url, body);
        console.log(`addSocialMediaLink returned ${JSON.stringify(resp)}`);

        return resp.data;
    }

    async removeSocialMediaLink(creatorId, mediaName) {
        const url = `${AppConfig.creatorApi}/removeSocialMediaLink/${creatorId}/${mediaName}`;
        let queryParams = {};

        const resp = await this.executeDelete(url, queryParams);

        return resp.data;
    }
}