import React,{useEffect, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Markdown from 'react-markdown';
import blogBg from '../../assets/images/bg/art_gallery_1.png';
import { ApplicationsService } from '../../services';
import { AssetService } from '../../services';
import { BlogService } from '../../services';
import { CreatorService } from '../../services';
import AppConfig from '../../App.config';
import { store } from '../../state';
import npcImg from '../../assets/images/npc.gif';
import BlogComment from '../../components/blog-comment';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const appSrv = new ApplicationsService();
const assetSrv = new AssetService();
const blogSrv = new BlogService();
const creatorSrv = new CreatorService();


export default function BlogDetail() {
  const [creatorProfileImage, setCreatorProfileImage] = useState(null);
  const [blogData, setBlogData] = useState(null);
  const [creatorData, setCreatorData] = useState(null);
  const [addingComment, setAddingComment] = useState(false);
  const [commentText, setCommentText] = useState('');

  const params = useParams();
  const id = params.id;
  const nameParam = params.name;
  const titleParam = params.title;
  const getCreatorData = async (creatorId)  => {
    const promises = [];
    promises.push(appSrv.getApplicationFile(creatorId, AppConfig.appVars.creatorProfileImg)
    .then(d => {
        if (d !== null) {
            setCreatorProfileImage(d)
        }
    })
    .catch(() => {
        console.log ('Creator profile image not found, using default');
    })); 
    promises.push(creatorSrv.getCreator(creatorId)
    .then(d => {
        if (d !== null) {
            setCreatorData(d)
        }
    })
    .catch(() => {
        console.log ('Creator data was not found');
    })); 

    await Promise.all(promises);
}

const getBlogRecord = async () => {
    if (nameParam !== undefined && titleParam !== undefined) {
        await blogSrv.getByFriendlyName(nameParam, titleParam)
        .then(resp => {
            if (resp) {
                setBlogData(resp);
                return resp.creatorId;
            } else {
                return null;
            }
        })
        .then(async cid => {
            await getCreatorData(cid);
        });
    } else {
        await blogSrv.getBlogById(id)
        .then(resp => {
            if (resp) {
                setBlogData(resp);
                return resp.creatorId;
            } else {
                return null;
            }
        })
        .then(async cid => {
            await getCreatorData(cid);
        })
    }
};

useEffect(()=>{
    getBlogRecord();
    if(store.state.user.loggedIn) {
        toast.success('logged in');
    } else {
        toast.error('not logged in');
    }
}, []);

const handleSetCommentText = (v) => {
    setCommentText(v);
}

const handleCancelComment = () => {
    setCommentText('');
    setAddingComment(false);
    toast.info('Comment cancelled');
}

const handleAddComment = async () => {
    try {
        const resp = await blogSrv.addBlogComment(blogData.blogId, 
            store.state.user.getId(), 
            store.state.user.getEmailAddress(), 
            commentText);
        
        if (resp?.status === 201) {
            setCommentText('');
            setAddingComment(false);
            toast.success('Commnent added.');

            getBlogRecord();
        } else {
            toast.warning(`Could not add comment: ${resp?.error}`);
        }
    } catch {
        toast.error('Failed to add comment');
    }
}

  return (
   <>
   <Navbar navlight={true}/>

    <section className="section mt-5">
      <div className="container">
        {blogData && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-lg-8">
                <div className="title-heading">
                    <h4 className="heading sub-heading fw-bold mb-3">{blogData.title}</h4>
                    <p className="text-muted  mb-0">{blogData.summary}</p>
                
                    <div className="d-flex align-items-center mt-4">
                        <div className="profile-pic">
                            <img src={creatorProfileImage !== null ? creatorProfileImage : npcImg} className="rounded-pill shadow-md" width="48" height="48" alt=""/>
                        </div>
                        <div className="content ms-2">
                            <Link to={`/creator-profile/${creatorData?.displayName ? creatorData.displayName : blogData.creatorId}`} className="h6 text-dark d-block mb-0">{creatorData?.displayName ? creatorData.displayName : blogData.creatorId}</Link>
                            <small className="text-muted mb-0">{blogData.updatedAt}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-8 mt-5">
                <img src={blogData.titleImage ? blogData.titleImage : blogBg} className="img-fluid rounded-md shadow" alt={blogData.title}/>
            </div>

            <div className="col-lg-8 mt-5">
                <Markdown>{blogData.data}</Markdown>
            </div>
            <div className="col-lg-8 mt-5">
                <h5>Comments</h5>
            </div>
            {blogData?.comments?.comments?.map(c => {
                return (
                <>
                    <BlogComment 
                        blogId={c.blogId} 
                        commentId={c.id}
                        author={c.emailAddress.split('@')[0]}
                        commentDate={c.updatedAt}
                        comment={c.commentText}>
                    </BlogComment>
                    
                </>
                );
            })}
            {addingComment && (
            <div className="col-lg-8 mt-5">
                <small className="text-muted d-block">Comment Text</small>
                <div className="iconed-input d-flex flex-row">
                    <input 
                        name="commentText" 
                        id="commentText" 
                        type="text" 
                        className="form-control" 
                        placeholder="comment" 
                        onChange={(e) => handleSetCommentText(e.target.value)} 
                        value={commentText} />
                        <i className="uil uil-plus fs-5 add-comment-tooltip" onClick={handleAddComment}/>
                        <Tooltip anchorSelect=".add-comment-tooltip" place="top">
                            Add Comment
                        </Tooltip>
                        <i className="uil uil-minus fs-5 cancel-comment-tooltip" onClick={handleCancelComment}/>
                        <Tooltip anchorSelect=".cancel-comment-tooltip" place="top">
                            Cancel
                        </Tooltip>
                </div>
            </div>
            )}
            {(store.state.user?.loggedIn && !addingComment) && (
            <div className="d-flex justify-content-end mt-3 col-lg-8">
                <Link to="#" 
                    id="back-to-top" 
                    onClick={()=>setAddingComment(true)} 
                    className="round-button fs-5">
                    <i className="uil uil-plus"></i>
                </Link>
            </div>
            )}
        </div>
        )}
        {blogData === null && (
            <h2>Content was not found.</h2>
        )}
      </div>
    </section>
    <Footer/>
    <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
    />
   </>
  )
}
