const CREATOR_API = 'https://createdbyme.io/creator-service';
const CREATOR_SERIES_API = 'https://createdbyme.io/creator-series-service';
const ASSET_API = 'https://createdbyme.io/asset-service';
const IPFS_API = 'https://createdbyme.io/ipfs-service';
const USER_API = 'https://createdbyme.io/user-service';
const APP_API = 'https://createdbyme.io/app-service';
const BLOG_API = 'https://createdbyme.io/blog-service';
const EVENT_API = 'https://createdbyme.io/view-event-service';
const IPFS_RETRIEVE_PATH = "https://createdbyme.io/ipfs";

const APP_VARS = {
    userProfileImg: "userProfileImg",
    creatorProfileImg: "creatorProfileImg",
    creatorBackground: "creatorBackground",
    creatorCardImg: "creatorCardImg",
    creatorUuidDocument: "uuidjson",
}

const appConfig = () => {
    return {
        creatorApi: CREATOR_API,
        creatorSeriesApi: CREATOR_SERIES_API,
        assetApi: ASSET_API,
        ipfsApi: IPFS_API,
        userApi: USER_API,
        appApi: APP_API,
        blogApi: BLOG_API,
        eventApi: EVENT_API,
        appVars: APP_VARS,
        ipfsRetrievePath: IPFS_RETRIEVE_PATH,
    };
};

export default appConfig();