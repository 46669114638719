import React from 'react';

export default function WorkDocument({
    url,
    title
}) {
    return (
        <>
        <a href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary rounded-md">
            {title}
            | view
            <i className="uil uil-arrow-right"></i>
        </a>
        </>
    );
}