import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from "../../components/footer";
import bg1 from '../../assets/images/bg/01.jpg'
import client1 from '../../assets/images/client/01.jpg'
import cbg from '../../assets/images/work/1.jpg';
import {ApplicationsService, CreatorService} from '../../services';
import { SocialMediaHelper } from '../../helpers';
import { useNavigate } from "react-router-dom";
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import defaultUserImg from '../../assets/images/default_user.gif';
import AppConfig from '../../App.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const mediaTypes = ['website', 'twitter', 'facebook', 'instagram', 'other'];
const usedMediaTypes = [];

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();

export default function CreatorProfileEdit() {
    let [creatorData, setCreatorData] = useState(null);
    let [creatorStory, setCreatorStory] = useState(null);
    let [creatorStoryValid, setCreatorStoryValid] = useState(false);
    let [addedMediaName, setAddedMediaName] = useState('');
    let [addedMediaLink, setAddedMediaLink] = useState('');
    let [addingMediaLink, setAddingMediaLink] = useState(false);
    let [otherMediaType, setOtherMediaType] = useState(false);
    //let [replaceUserImg, setReplaceUserImg] = useState(false);
    let [userProfileImg, setUserProfileImg] = useState(defaultUserImg);
    let [creatorBgImg, setCreatorBgImg] = useState(cbg);
    let [creatorCardImg, setCreatorCardImg] = useState(cbg);

    const navigate = useNavigate();

    const { creatorId } = useParams();
  
    useEffect(()=>{
        const getCreatorRecord = async (id) => {
            if (id) {
                const regex = /^(0x)?[0-9a-fA-F]{40}$/;
                if (regex.test(id)) {
                    await creatorSrv.getCreator(id).then(rec => {
                        updateCreatorRecord(rec);
                    });
                } else {
                    await creatorSrv.getCreatorByName(id).then(rec => {
                        updateCreatorRecord(rec);
                    });
                }
            }
        };

        getCreatorRecord(creatorId);
        getCreatorBgImage();
        getCardBgImage();
        
        if (store.state.user.loggedIn) {
            getUserProfileImage(store.state.user.getId());
        }
    }, []);

    const getUserProfileImage = async (userId) => {
        let upi = null;
        upi = await appSrv.getApplicationFile(creatorId, AppConfig.appVars.creatorProfileImg);
        
        if (!upi) {
            upi = await appSrv.getApplicationFile(`user_${userId}`, AppConfig.appVars.userProfileImg);
        }

        if (upi) {
            setUserProfileImg(upi);
        }
    }

    const getCreatorBgImage = async () => {
        let upi = null;

        upi = await appSrv.getApplicationFile(creatorId, AppConfig.appVars.creatorBackground);

        if (upi) {
            setCreatorBgImg(upi);
        }
    }

    const getCardBgImage = async () => {
        let upi = null;

        upi = await appSrv.getApplicationFile(creatorId, AppConfig.appVars.creatorCardImg);

        if (upi) {
            setCreatorCardImg(upi);
        }
    }

    const updateCreatorRecord = (rec) => {
        rec.links = rec.links.filter(l => {
            l.className = SocialMediaHelper.getSocialMediaClass(l.mediaName);
            return l;
        });
        setCreatorData(rec);
        setCreatorStory(rec.story);
        setCreatorStoryValid(true);
        adjustMediaItems(rec);
    }

    const adjustMediaItems = (data = null) => {
        if (data) {
            for(let i = 0; i < data.links?.length; i++) {
                if(mediaTypes.indexOf(data.links[i])) {
                    if (usedMediaTypes.indexOf(data.links[i].mediaName) < 0) {
                        usedMediaTypes.push(data.links[i].mediaName);
                    }
                }
            }
        } else if(creatorData) {
            for(let i = 0; i < creatorData.links?.length; i++) {
                if(mediaTypes.indexOf(creatorData.links[i])) {
                    if (usedMediaTypes.indexOf(creatorData.links[i].mediaName) < 0) {
                        usedMediaTypes.push(creatorData.links[i].mediaName);
                    }
                }
            }
        }
    }

    const handleStoryChange = (val) => {
        setCreatorStory(val);

        if (val.length > 0) {
            setCreatorStoryValid(true);
        } else  {
            setCreatorStoryValid(false);
        }
    }

    const addMediaLink = () => {
        setAddedMediaName('');
        setAddedMediaLink('');
        setAddingMediaLink(true);
        setOtherMediaType(false);
    }

    const handleAddMedia = async () => {
        await creatorSrv.addSocialMediaLink(creatorData.creatorId, addedMediaName, addedMediaLink).then(() => {
            toast.success('Social media link added successfully.');
        })
        .catch(exp => {
            toast.error('Adding social media link may have failed.');
        });

        await creatorSrv.getCreator(creatorData.creatorId).then(rec => {
            updateCreatorRecord(rec);
            adjustMediaItems(rec);
            setAddedMediaName('');
            setAddedMediaLink('');
            setAddingMediaLink(false);
            setOtherMediaType(false);
        });
    }

    const handleRemoveMediaItem = async (idx) => {
        await creatorSrv.removeSocialMediaLink(creatorData.creatorId, creatorData.links[idx].mediaName).then(() => {
            toast.success('Social media link removed successfully.');
        })
        .catch(exp => {
            toast.error('Adding social media link may have failed.');
        });
        await creatorSrv.getCreator(creatorData.creatorId).then(rec => {
            updateCreatorRecord(rec);
            adjustMediaItems(rec);
        });
    }

    const handleMediaTypeSelectChange = (e) => {
        if (e.target.value === 'other') {
            setOtherMediaType(true);
            setAddedMediaName('');
        } else {
            setAddedMediaName(e.target.value);
        }
    };

    const handleSubmit = async () => {
        console.log('creator handleSubmit called');

        if (creatorStoryValid) {
            try {
                toast.info('Submitting update for your story. This will take a few seconds ...');
                const resp = await creatorSrv.updateCreatorStory(creatorId, creatorStory);

                if (resp.status == 202) {
                    toast.success("Profile updated successfully.");

                    await creatorSrv.getCreator(creatorId).then(rec => {
                        updateCreatorRecord(rec);
                    });
                } else {
                    toast.error(`Update failed: ${resp.error}`);
                }
            } catch {
                toast.error('Update failed');
            }
        } else {
            toast.error('Please correct form errors.');
        }
    }

    const handleProfileImageChange = async (e) => {
        var reader = new FileReader();

        if (store.state.user.loggedIn) {
            const id = store.state.user.getId();
            storeImageData(creatorId, AppConfig.appVars.creatorProfileImg, e.target.files[0]);

            await getUserProfileImage(id);
        }
    }

    function handleCardImageChange(e) {
        setCreatorCardImg(e.target.files[0]);
        storeImageData(creatorId, AppConfig.appVars.creatorCardImg, e.target.files[0]);
    }

    function handleCreatorBackgroundChange(e) {
        setCreatorBgImg(URL.createObjectURL(e.target.files[0]));
        storeImageData(creatorId, AppConfig.appVars.creatorBackground, e.target.files[0]);
    }

    function storeImageData(id, mediaName, fileData) {
        var reader = new FileReader();
        reader.onloadend = async () => {
            await appSrv.storeApplicationFile(id, mediaName, reader.result).then(() => {
                toast.success(`${mediaName} saved successfully.`);
            })
            .catch(exp => {
                toast.error(`Savif ${mediaName} may have failed.`);
            });
        }

        reader.readAsDataURL(fileData);
    }

    return (
    <>
    <Navbar navlight={true}/>
    <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url("${creatorBgImg}")`, backgroundPosition:'bottom'}}>
      <div className="bg-overlay bg-gradient-overlay-2"></div>
      <div className="container">
          <div className="row mt-5 justify-content-center">
              <div className="col-12">
                  <div className="title-heading text-center">
                      <h5 className="heading fw-semibold sub-heading text-white title-dark">Edit Profile</h5>
                      <p className="text-white-50 para-desc mx-auto mb-0">Edit your profile</p>
                  </div>
                  <div className="card mt-4 text-center">
                    <div className="profile-pic">
                        <input id="pro-img" name="profile-image" type="file" className="d-none" onChange={(e)=>handleProfileImageChange(e)} />
                        <div className="position-relative d-inline-block">
                            <img src={userProfileImg} className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt=""/>
                            <label className="icons position-absolute bottom-0 end-0" htmlFor="pro-img"><span className="btn btn-icon btn-sm btn-pills btn-primary"><i className="uil uil-camera fs-6"></i></span></label>
                        </div>
                    </div>
                </div>
              </div>
          </div>

          <div className="position-middle-bottom">
            <input id="pro-banner" name="profile-banner" type="file" className="d-none" onChange={(e)=>handleCreatorBackgroundChange(e)} />
            <label className="icons position-absolute bottom-5 end-0" htmlFor="pro-banner"><span className="btn btn-icon btn-sm btn-pills btn-primary"><i className="uil uil-camera fs-6"></i></span></label>      

              <nav aria-label="breadcrumb" className="d-block">
                  <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Profile Edit</li>
                  </ul>
              </nav>
          </div>
      </div>
  </section>
  <div className="position-relative">            
      <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
      </div>
  </div>
  <section className="section pt-2">
    <div className="container">
        {creatorData == null && (
        <div className="row">
            <div className="col-12 order-2 order-md-1 mt-4 pt-2">
                <div className="rounded-md shadow">
                    <div className="p-4 border-bottom">
                        <h5 className="mb-0">Creator record not found</h5>
                    </div>
                </div>
            </div>
        </div>
        )}
        {creatorData && (
        <div className="row">
            <div className="col-12 order-2 order-md-1 mt-4 pt-2">
                <div className="rounded-md shadow">
                    <div className="p-4 border-bottom">
                        <h5 className="mb-0">Edit Profile:</h5>
                    </div>

                    <div className="p-4">
                        <form className="profile-edit">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <label className="form-label">
                                                Display Name 
                                                <i className="mdi mdi-vector-link display-name-tooltip mx-2" />
                                                <Tooltip anchorSelect=".display-name-tooltip" place="top">
                                                    This value is stored on chain.
                                                </Tooltip>
                                    </label>
                                    <small className="text-muted d-block">This is your unique identifying name and cannot be changed.</small>
                                    <h5>{creatorData.displayName}</h5>
                                </div>

                                <div className="col-12 mb-4">
                                    <label className="form-label">Your Story
                                        <i className="mdi mdi-vector-link story-tooltip mx-2" />
                                        <Tooltip anchorSelect=".story-tooltip" place="top">
                                            This value is stored on chain.
                                        </Tooltip>
                                    </label>
                                    <small className="text-muted d-block">This is your chance to tell your unique story. What are you works about? WHat drives you? Why do you do, what you do?</small>
                                    <textarea 
                                        name="comments" 
                                        id="comments" 
                                        rows="3" 
                                        className="form-control" 
                                        value={creatorStory}
                                        placeholder="I'm a Digital Artist. Digital Art with over 3 years of experience. Experienced with all stages of the Art cycle for dynamic projects."
                                        onChange={(e) => handleStoryChange(e.target.value)}></textarea>
                                        { !creatorStoryValid && (
                                            <span className="warning-text ">required</span>
                                        )}
                                </div>

                                
                            </div>

                            
                            <div className="row">
                                <div className="col-12">
                                    <button 
                                    type="button" 
                                    id="submit" 
                                    name="send" 
                                    onClick={handleSubmit}
                                    className="btn btn-primary rounded-md">Update Profile</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="rounded-md shadow">
                    <div className="p-4 border-bottom">
                        <h5 className="mb-0">Media Links:</h5>
                    </div>

                    <div className="p-4">
                        <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4 "> 
                        {creatorData?.links.map((l,index) => {
                            return(
                            <li className="list-inline-item lh-1 border-bottom row pb-2 mb-3" key={`media_link_${l.mediaName}_${index}` }>
                                <div className="col-12 ">
                                    <i className={l.className + " mx-2"}></i>
                                    <label>{l.mediaName}</label>
                                </div>
                                <div className="col-12 my-3">
                                    <input className="w-75" disabled={true} value={l.mediaUrl}></input>
                                    <button 
                                        type="button" 
                                        id={`rm_${index}`} 
                                        onClick={() => handleRemoveMediaItem(index)}
                                        className="btn btn-primary rounded-md mx-2">
                                        <i className="uil uil-trash-alt"></i>
                                    </button>
                                </div>
                            </li>
                            );
                            })
                        }
                        </ul>
                    </div>

                    <div className="p-4">
                        {(addingMediaLink !== true) && (
                        <button 
                            type="button" 
                            id="addLinkBtn" 
                            name="addLinkBtn" 
                            onClick={() => addMediaLink()}
                            className={"btn btn-primary rounded-md" }>
                            Add Link
                        </button>
                        )}
                        {addingMediaLink  && (
                            <div className="row">
                                <div className="col-12">
                                    <label className="form-label d-block">Media Type
                                        <i className="mdi mdi-help-circle-outline media-type-tooltip mx-2" />
                                        <Tooltip anchorSelect=".media-type-tooltip" place="top">
                                            Type of media link (i.e. "twitter", "instagram", "email", etc).
                                        </Tooltip>
                                    </label>
                                    {(otherMediaType !== true) && (
                                    <select 
                                        id="linkTypes"
                                        className="mb-3"
                                        value={addedMediaName}
                                        onChange={handleMediaTypeSelectChange}
                                    >
                                        {mediaTypes.map ((t) => {
                                            if (usedMediaTypes.indexOf(t) < 0) {
                                                return <option key={t} value={t}>{t}</option>
                                            }
                                        })}
                                    </select>
                                    )}
                                    {otherMediaType && (
                                        <input 
                                            className="mb-3 w-75"
                                            onChange={(e) => setAddedMediaName(e.target.value)}
                                            placeholder="My media type"
                                            value={addedMediaName} ></input>
                                    )}

                                    <label className="form-label d-block">Media Link
                                        <i className="mdi mdi-help-circle-outline media-link-tooltip mx-2" />
                                        <Tooltip anchorSelect=".media-link-tooltip" place="top">
                                            URL of media link.
                                        </Tooltip>
                                    </label>
                                    <input 
                                        className="mb-3 w-75"
                                        onChange={(e) => setAddedMediaLink(e.target.value)}
                                        value={addedMediaLink} ></input>
                                    
                                    <button 
                                        type="button" 
                                        id="addLinkBtn" 
                                        name="addLinkBtn" 
                                        onClick={() => handleAddMedia()}
                                        className={"btn btn-primary rounded-md mx-2" }>
                                        Add
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-center">
                        <h4>Set Profile Card Image</h4>
                        <input id="card-bg-img" name="card-bg-image" type="file" className="d-none" onChange={(e)=>handleCardImageChange(e)} />
                        <div className="position-relative d-inline-block card-base">
                            <img src={creatorCardImg} className="py-5" id="card-background-image" alt=""/>
                            <label className="icons position-absolute bottom-0 end-0" htmlFor="card-bg-img"><span className="btn btn-icon btn-sm btn-pills btn-primary"><i className="uil uil-camera fs-6"></i></span></label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        )}
    </div>
  </section>
  <Footer/>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />           
    </>
  )
}
