import { BaseService } from './base.service';
import AppConfig from '../App.config';
import axios from "axios";

export class IpfsService extends BaseService {
    async upload (file) {
        const url = `${AppConfig.ipfsApi}/upload`;
        
        const body = {file: file};

        const resp = await axios.post(url, body, {headers: {
            'Content-Type': 'multipart/form-data'
        }})
        .then(function (response) {
            if (response.status !== 201) {
                console.log(`POST Request failed with: ${response.statusText}`);
            }
            
            return response.data
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });

        console.log(`Upload returned: ${JSON.stringify(resp)}`)
        return resp;
    }

    async uploadObjectAsJson (name, obj) {
        const url = `${AppConfig.ipfsApi}/uploadJson`;
        
        const body = {
            name: name,
            data: JSON.stringify(obj)
        };

        const resp = await axios.post(url, body, {headers: {
            'Content-Type': 'application/json'
        }})
        .then(function (response) {
            if (response.status !== 201) {
                console.log(`POST Request failed with: ${response.statusText}`);
            }
            
            return response.data
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });

        console.log(`Upload returned: ${JSON.stringify(resp)}`)
        return resp;
    }

    async removeQid(qid) {
        const url = `${AppConfig.ipfsApi}/removeIpfsResource/${qid}`;

        const resp = await this.executeDelete(url, {});

        return resp.data;
    }
}