import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import BackToHome from '../../components/back-to-home'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import bg1 from '../../assets/images/bg/artists_2.png'
import logoLight from '../../assets/images/logo_light_64.png'

export default function AlphaLanding() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('id')) {
            const id = searchParams.get('id');
            console.log(`found id ${id}`);
            navigate(`/work/${id}`);
        }
    }, []);
    
  return (
    <>
      <section className="position-relative zoom-image">
        <div className="bg-overlay image-wrap" style={{backgroundImage:`url("${bg1}")` , backgroundPosition:'bottom'}}></div>
        <div className="bg-overlay"></div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="d-flex flex-column min-vh-100 p-4">
                        <div className="text-center">
                            <Link to="/"><img src={logoLight} alt=""/></Link>
                        </div>

                        <div className="title-heading my-auto">
                            <div className="text-center mt-5" >
                                <small >A space where artists can establish their brand and tell their story</small>
                            </div>
                            <h4 className="coming-soon fw-bold display-5 text-center text-white title-dark text-uppercase mt-5">ALPHA 1</h4>
                            <p className="text-white title-dark para-desc mx-auto mb-0">
                            This is the first ALPHA release of the Created by Me Application. Created by Me is
                            an application that will provide Artists, craftsmen, and other artisans
                            with the ability to display, sell, and auction their artistic works. Additionally the 
                            application establishes provenance of the works via the providing immutable, tamperproof, secured, 
                            transparent and auditable properties of the blockchain.
                            </p>

                            <p className="text-white title-dark para-desc mx-auto mb-0 mt-3">
                            An ALPHA release is an application that is still under heavy development,
                            may be unstable, incosistant, and contain defects. 
                            </p>
        
                            <div className="row text-center justify-content-center" >
                                <div className="col-xl-10 mt-lg-5">
                                    <h3 className="mt-4 pt-2">
                                        <Link to="/home" className="btn btn-pills btn-outline-light-white">
                                            <h3>Home Page</h3>
                                        </Link>
                                    </h3>
                                </div>

                                <div className="col-xl-10 mt-lg-5">
                                    <h3 className="mt-4 pt-2">
                                        <Link to="/road-map" className="btn btn-pills btn-outline-light-white">
                                            <h3>Road Map</h3>
                                        </Link>
                                    </h3>
                                </div>
                                
                                <div className="col-xl-10 mt-lg-5">                                    
                                    <h3 className="mt-4 pt-2">
                                        <Link to="#" className="btn btn-pills btn-outline-light-white">
                                            <h3>Bug List [comming soon]</h3>
                                        </Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-5">
                            <small className="mb-0 text-light title-dark">© {new Date().getFullYear()} CreatedByMe <i className="mdi mdi-heart text-danger"></i> by <Link to="https://1155project.com/" target="_blank" className="text-reset">1155project</Link>.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BackToHome/>
    </>
  )
}
