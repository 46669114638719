import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AssetMetadataResource } from '../../models/asset-metadata-resource';
import Dropzone from 'react-dropzone';
import AppConfig from '../../App.config';

export default function AddWorkProperty({
    propKey,
    resource,
    saveCallback,
    imageCallback,
    propertyKeys = null
}) {
    const [ itemKey, setItemKey ] = useState('');
    const [ itemData, setItemData ] = useState({});
    const [ itemKeyValid, setItemKeyValid ] = useState(false);
    const [ itemKeyDuplicate, setItemKeyDuplicate ] = useState(false);
    const [ keys, setKeys ] = useState([]);

    useEffect(()=>{
        const r = resource !== null ? resource : new AssetMetadataResource();
        setItemKey(propKey);
        setItemData(r);

        setItemKeyValid (propKey !== null && propKey !== '');

        if (propertyKeys) {
            setKeys(propertyKeys.split('|'));
        }
    }, []);

    const onDrop = async (acceptedFiles) => {
        const qid = await imageCallback(acceptedFiles);

        if (qid !== null) {
            itemData['url'] = `${AppConfig.ipfsRetrievePath}/${qid}`
        }
    };

    const updateProperty = (key, val) => {
        const item = itemData;
        item[key] = val;
        setItemData(item);
    }

    const handleVideoUpdate = (val) => {
        itemData['url'] = val;

        if (itemData['options'] === null) {
            itemData['options'] = {};
        }

        if (val.toLowerCase().indexOf('youtube') >= 0) {
            itemData['options']['videoSource'] = 'youtube';
        } else if (val.toLowerCase().indexOf('vimeo') >= 0) {
            itemData['options']['videoSource'] = 'vimeo';
        } else {
            itemData['options']['videoSource'] = 'other';
        }
    }

    const updateOption = (key, val) => {
        let item = itemData;
        if (item['options'] === null) item['options'] = {};

        item['options'][key] = val
    }

    const updatePropertyName = (val) => {
        const v = val.toLowerCase().replace(' ', '-');

        if ( keys.indexOf(v) >= 0) {
            setItemKeyDuplicate(true);
        }
        
        setItemKeyValid (v !== '' && keys.indexOf(v) < 0);
        setItemKey(v);
    }

    return (
    <>

        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Property Name 
                </label>
                <small className="text-muted d-block">Unique name. Lowercase, no spaces.</small>
                <input 
                    name="propertyName" 
                    id="propertyName" 
                    type="text" 
                    className="form-control" 
                    placeholder="property name" 
                    value={itemKey}
                    onChange={(e) => updatePropertyName(e.target.value)}/>
                <span className={"warning-text " + (itemKeyValid ? "" : "hidden")}>required</span>
                <span className={"warning-text " + (itemKeyDuplicate ? "" : "hidden")}>property name already used</span>
            </div>
        </div>

        {itemData?.type === 'LINK' && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Link Title 
                </label>
                <small className="text-muted d-block">The text for this link.</small>
                <input 
                    name="linkTitle" 
                    id="linkTitle" 
                    type="text" 
                    className="form-control" 
                    placeholder="title" 
                    value={itemData['title'] !== null ? itemData['title'] : ''}
                    onChange={(e) => updateProperty('title', e.target.value)}/>
                <span className={"warning-text " + (itemData['title']  ? "" : "hidden")}>{itemData['title'] }</span>
            </div>
            <div className="col-10 mb-4">
                <label className="form-label">
                    Link Url 
                </label>
                <small className="text-muted d-block">The target URL / address of the resource.</small>
                <input 
                    name="linkUrl" 
                    id="linkUrl" 
                    type="text" 
                    className="form-control" 
                    placeholder="streetboyyy" 
                    value={itemData['url'] !== null ? itemData['url'] : ''}
                    onChange={(e) => updateProperty('url', e.target.value)}/>
                <span className={"warning-text " + (itemData['url'] ? "" : "hidden")}>{itemData['url']}</span>
            </div>
        </div>
        )}
        {itemData?.type === 'IMAGE' && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Image Title 
                </label>
                <small className="text-muted d-block">Title of the image.</small>
                <input 
                    name="imageTitle" 
                    id="imageTitle" 
                    type="text" 
                    className="form-control" 
                    placeholder="title" 
                    value={itemData['title'] !== null ? itemData['title'] : ''}
                    onChange={(e) => updateProperty('title', e.target.value)}/>
                <span className={"warning-text " + (itemData['title']  ? "" : "hidden")}>{itemData['title'] }</span>
            </div>
            <div className="col-10 mb-4">
                <label className="form-label">
                    Image Url 
                </label>
                <small className="text-muted d-block">The target URL / address of the resource.</small>
                { itemData['url'] && (
                <img 
                    src={itemData['url']} 
                    width="128" 
                    alt={itemData['title']}
                    className="border border-white rounded mt-5"></img>
                )}
                { itemData['url'] === null (
                    <Dropzone onDrop={onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="load-img-dz">
                        <input {...getInputProps()}/>
                         
                        </div>
                    )}
                    </Dropzone>
                )}
                <span className={"warning-text " + (itemData['url'] ? "" : "hidden")}>{itemData['url']}</span>
            </div>
        </div>
        )}
        {itemData?.type === 'VIDEO' && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Video Title 
                </label>
                <small className="text-muted d-block">The title for this video.</small>
                <input 
                    name="linkTitle" 
                    id="linkTitle" 
                    type="text" 
                    className="form-control" 
                    placeholder="title" 
                    value={itemData['title'] !== null ? itemData['title'] : ''}
                    onChange={(e) => updateProperty('title', e.target.value)}/>
                <span className={"warning-text " + (itemData['title']  ? "" : "hidden")}>{itemData['title'] }</span>
            </div>
            <div className="col-10 mb-4">
                <label className="form-label">
                    {itemData['options'] && itemData['options']['videoSource'] ? itemData['options']['videoSource'] : 'other'}
                </label>
            </div>
            
            <div className="col-10 mb-4">
                <label className="form-label">
                    Link Url 
                </label>
                <small className="text-muted d-block">The target URL / address of the resource.</small>
                <input 
                    name="linkUrl" 
                    id="linkUrl" 
                    type="text" 
                    className="form-control" 
                    placeholder="streetboyyy" 
                    value={itemData['url'] !== null ? itemData['url'] : ''}
                    onChange={(e) => handleVideoUpdate(e.target.value)}/>
                <span className={"warning-text " + (itemData['url'] ? "" : "hidden")}>{itemData['url']}</span>
            </div>
        </div>
        )}
        {itemData?.type === 'DOCUMENT' && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Document Title 
                </label>
                <small className="text-muted d-block">Title of the document.</small>
                <input 
                    name="docTitle" 
                    id="docTitle" 
                    type="text" 
                    className="form-control" 
                    placeholder="title" 
                    value={itemData['title'] !== null ? itemData['title'] : ''}
                    onChange={(e) => updateProperty('title', e.target.value)}/>
                <span className={"warning-text " + (itemData['title']  ? "" : "hidden")}>{itemData['title'] }</span>
            </div>
            <div className="col-10 mb-4">
                <label className="form-label">
                    Image Url 
                </label>
                <small className="text-muted d-block">The target URL / address of the resource.</small>
                { itemData['url'] && (
                <input 
                    name="docUrl" 
                    id="docUrl" 
                    type="text" 
                    className="form-control" 
                    placeholder="streetboyyy" 
                    value={itemData['url'] !== null ? itemData['url'] : ''}
                    onChange={(e) => updateProperty('url', e.target.value)}/>
                )}
                { itemData['url'] === null (
                    <Dropzone onDrop={onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="load-img-dz">
                        <input {...getInputProps()}/>
                        
                        </div>
                    )}
                    </Dropzone>
                )}
                <span className={"warning-text " + (itemData['url'] ? "" : "hidden")}>{itemData['url']}</span>
            </div>
        </div>
        )}
        {itemData?.type === 'PROPERTY' && (
        <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-10 mb-4">
                <label className="form-label">
                    Property Value 
                </label>
                <small className="text-muted d-block">The property's value</small>
                <input 
                    name="linkUrl" 
                    id="linkUrl" 
                    type="text" 
                    className="form-control" 
                    placeholder="value" 
                    value={itemData}
                    onChange={(e) => setItemData(e.target.value)}/>
                <span className={"warning-text " + (itemData ? "" : "hidden")}>required</span>
            </div>
        </div>
        )}
        <button 
            type="button" 
            id="saveResourceBtn" 
            name="saveResourceBtn" 
            onClick={() => saveCallback(itemKey, itemData)}
            className={"btn btn-primary rounded-md" }>
            Save
        </button>
    </>
    )
}