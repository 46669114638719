import createrBg1 from '../assets/images/work/1.jpg'
import createrBg2 from '../assets/images/work/2.jpg'
import createrBg3 from '../assets/images/work/3.jpg'
import createrBg4 from '../assets/images/work/4.jpg'
import createrBg5 from '../assets/images/work/5.jpg'
import createrBg6 from '../assets/images/work/6.jpg'
import createrBg7 from '../assets/images/work/7.jpg'
import createrBg8 from '../assets/images/work/8.jpg'
import createrBg9 from '../assets/images/work/9.jpg'
import createrBg10 from '../assets/images/work/10.jpg'
import createrBg11 from '../assets/images/work/11.jpg'
import createrBg12 from '../assets/images/work/12.jpg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'
import client9 from '../assets/images/client/09.jpg'
import client10 from '../assets/images/client/10.jpg'
import client11 from '../assets/images/client/11.jpg'
import client12 from '../assets/images/client/12.jpg'
import client13 from '../assets/images/client/13.jpg'

import product1 from '../assets/images/gif/7.gif'
import product2 from '../assets/images/items/1.jpg'
import product3 from '../assets/images/gif/8.gif'
import product4 from '../assets/images/items/2.jpg'
import product5 from '../assets/images/items/3.jpg'
import product6 from '../assets/images/gif/9.gif'
import product7 from '../assets/images/items/4.jpg'
import product8 from '../assets/images/gif/10.gif'
import product9 from '../assets/images/items/5.jpg'
import product10 from '../assets/images/gif/5.gif'
import product11 from '../assets/images/items/6.jpg'
import product12 from '../assets/images/gif/6.gif'
import product13 from '../assets/images/items/7.jpg'
import product14 from '../assets/images/items/8.jpg'
import product15 from '../assets/images/items/9.jpg'
import product16 from '../assets/images/items/10.jpg'
import product17 from '../assets/images/gif/3.gif'
import product18 from '../assets/images/gif/4.gif'
import product20 from '../assets/images/gif/1.gif'

import blog1 from '../assets/images/blog/01.jpg'
import blog2 from '../assets/images/blog/02.jpg'
import blog3 from '../assets/images/blog/03.jpg'
import blog4 from '../assets/images/blog/04.jpg'
import blog5 from '../assets/images/blog/05.jpg'
import blog6 from '../assets/images/blog/06.jpg'
import blog7 from '../assets/images/blog/07.jpg'
import blog8 from '../assets/images/blog/08.jpg'
import blog9 from '../assets/images/blog/09.jpg'
import blog10 from '../assets/images/blog/10.jpg'
import blog11 from '../assets/images/blog/11.jpg'
import blog12 from '../assets/images/blog/12.jpg'

import collection1 from '../assets/images/collection/01.jpg'
import collection2 from '../assets/images/collection/02.jpg'
import collection3 from '../assets/images/collection/03.jpg'
import collection4 from '../assets/images/collection/04.jpg'
import collection5 from '../assets/images/collection/05.jpg'
import collection6 from '../assets/images/collection/06.jpg'
import collection7 from '../assets/images/collection/07.jpg'
import collection8 from '../assets/images/collection/08.jpg'
import collection9 from '../assets/images/collection/09.jpg'
import collection10 from '../assets/images/collection/10.jpg'
import collection11 from '../assets/images/collection/11.jpg'
import collection12 from '../assets/images/collection/12.jpg'
import collection13 from '../assets/images/collection/13.jpg'
import collection14 from '../assets/images/collection/14.jpg'
import collection15 from '../assets/images/collection/15.jpg'

import item1 from '../assets/images/items/16.jpg'
import item2 from '../assets/images/items/17.jpg'
import item3 from '../assets/images/items/18.jpg'
import item4 from '../assets/images/items/19.jpg'
import item5 from '../assets/images/items/20.jpg'
import item6 from '../assets/images/items/21.jpg'
import item7 from '../assets/images/items/22.jpg'
import item8 from '../assets/images/items/23.jpg'
import item9 from '../assets/images/items/24.jpg'
import item10 from '../assets/images/items/25.jpg'
import item11 from '../assets/images/items/26.jpg'
import item12 from '../assets/images/items/27.jpg'
import item13 from '../assets/images/items/28.jpg'
import item14 from '../assets/images/items/29.jpg'

import wallet1 from '../assets/images/wallet/metamask.5801d957d27c65deeef0.png'
import wallet2 from '../assets/images/wallet/aave.svg'
import wallet3 from '../assets/images/wallet/Airswap.svg'
import wallet4 from '../assets/images/wallet/Compound.svg'
import wallet5 from '../assets/images/wallet/ddexsvg.svg'
import wallet6 from '../assets/images/wallet/defi-saver.svg'
import wallet7 from '../assets/images/wallet/dYdX.svg'
import wallet8 from '../assets/images/wallet/idex.svg'
import wallet9 from '../assets/images/wallet/kyber.svg'
import wallet10 from '../assets/images/wallet/maker.svg'
import wallet11 from '../assets/images/wallet/nuo.svg'
import wallet12 from '../assets/images/wallet/PoolTogether.svg'
import wallet13 from '../assets/images/wallet/sablier.svg'
import wallet14 from '../assets/images/wallet/set.svg'
import wallet15 from '../assets/images/wallet/uniswap.svg'
import wallet16 from '../assets/images/wallet/zerion.svg'


export const createrData = [
    {
        id: 1,
        no:'01.',
        bgImage: createrBg1,
        image:client1,
        name: "Steven Townsend",
        title:'@StreetBoy',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 2,
        no:'02.',
        bgImage: createrBg2,
        image:client2,
        name: "Tiffany Betancourt",
        title:'@CutieGirl',
        value:'20.5 ETH'
    },
    {
        id: 3,
        no:'03.',
        bgImage: createrBg3,
        image:client3,
        name: "Mari Harrington",
        title:'@NorseQueen',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 4,
        no:'04.',
        bgImage: createrBg4,
        image:client4,
        name: "Floyd Glasgow",
        title:'@BigBull',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 5,
        no:'05.',
        bgImage: createrBg5,
        image:client5,
        name: "Donna Schultz",
        title:'@Angel',
        value:'20.5 ETH'
    },
    {
        id: 6,
        no:'06.',
        bgImage: createrBg6,
        image:client6,
        name: "Joshua Morris",
        title:'@CrazyAnyone',
        value:'20.5 ETH'
    },
    {
        id: 7,
        no:'07.',
        bgImage: createrBg7,
        image:client7,
        name: "Carl Williams",
        title:'@LooserBad',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 8,
        no:'08.',
        bgImage: createrBg8,
        image:client8,
        name: "Eugene Green",
        title:'@KristyHoney',
        value:'20.5 ETH'
    },
    {
        id: 9,
        no:'09.',
        bgImage: createrBg9,
        image:client9,
        name: "Julius Canale",
        title:'@PandaOne',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 10,
        no:'10.',
        bgImage: createrBg10,
        image:client10,
        name: "Michael Williams",
        title:'@FunnyGuy',
        value:'20.5 ETH',
        status:'active',
    },
    {
        id: 11,
        no:'11.',
        bgImage: createrBg11,
        image:client11,
        name: "Jacqueline Burns",
        title:'@ButterFly',
        value:'20.5 ETH'
    },
    {
        id: 12,
        no:'12.',
        bgImage: createrBg12,
        image:client12,
        name: "Rosaria Vargas",
        title:'@Princess',
        value:'20.5 ETH',
        status:'active',
    },
]

export const nftData = [
    {
        id: 1,
        creater1:client8,
        creater2:client5,
        creater3:client6,
        createrName:'Steven Townsend',
        title:'@StreetBoy',
        product:product1,
        name:'Deep Sea Phantasy',
        value:'20.5 ETH',
        category:'games',
        date:'March 29, 2025 6:0:0',
        showDate:true,
        tag:'GIFs'
    },
    {
        id: 2,
        creater1:client1,
        creater2:client2,
        creater3:client3,
        createrName:'Tiffany Betancourt',
        title:'@CutieGirl',
        product:product2,
        name:'CyberPrimal 042 LAN',
        value:'20.5 ETH',
        category:'art',
        date:'April 03, 2025 6:0:0',
        showDate:false,
        tag:'Arts'
    },
    {
        id: 3,
        creater1:client4,
        creater2:client5,
        creater3:client6,
        createrName:'Mari Harrington',
        title:'@NorseQueen',
        product:product3,
        name:'Crypto Egg Stamp #5',
        value:'20.5 ETH',
        category:'memes',
        date:'April 10, 2025 6:0:0',
        showDate:false,
        tag:'Games'
    },
    {
        id: 4,
        creater1:client7,
        creater2:client8,
        creater3:client9,
        createrName:'Floyd Glasgow',
        title:'@BigBull',
        product:product4,
        name:'Colorful Abstract Painting',
        value:'20.5 ETH',
        category:'video',
        date:'April 18, 2025 6:0:0',
        showDate:true,
        tag:'Memes'
    },
    {
        id: 5,
        creater1:client10,
        creater2:client11,
        creater3:client12,
        createrName:'Donna Schultz',
        title:'@Angel',
        product:product5,
        name:'Liquid Forest Princess',
        value:'20.5 ETH',
        category:'video',
        date:'May 01, 2025 6:0:0',
        showDate:false,
        tag:'Illustration'
    },
    {
        id: 6,
        creater1:client1,
        creater2:client2,
        creater3:client3,
        createrName:'Joshua Morris',
        title:'@CrazyAnyone',
        product:product6,
        name:'Spider Eyes Modern Art',
        value:'20.5 ETH',
        category:'games',
        date:'June 08, 2025 6:0:0',
        showDate:true,
        tag:'GIFs'
    },
    {
        id: 7,
        creater1:client4,
        creater2:client5,
        creater3:client6,
        createrName:'Carl Williams',
        title:'@LooserBad',
        product:product7,
        name:'Synthwave Painting',
        value:'20.5 ETH',
        category:'art',
        date:'June 20, 2025 6:0:0',
        showDate:false,
        tag:'GIFs'
    },
    {
        id: 8,
        creater1:client7,
        creater2:client8,
        creater3:client9,
        createrName:'Eugene Green',
        title:'@KristyHoney',
        product:product8,
        name:'Contemporary Abstract',
        value:'20.5 ETH',
        category:'music',
        date:'July 30, 2025 6:0:0',
        showDate:false,
        tag:'GIFs'
    },
    {
        id: 9,
        creater1:client10,
        creater2:client11,
        creater3:client12,
        createrName:'Julius Canale',
        title:'@PandaOne',
        product:product9,
        name:'Valkyrie Abstract Art',
        value:'20.5 ETH',
        category:'memes',
        date:'April 03, 2025 6:0:0',
        showDate:false,
        tag:'Games'
    },
    {
        id: 10,
        creater1:client1,
        creater2:client2,
        creater3:client3,
        createrName:'Michael Williams',
        title:'@FunnyGuy',
        product:product10,
        name:'The girl with the firefly',
        value:'20.5 ETH',
        category:'art',
        date:'April 10, 2025 6:0:0',
        showDate:false,
        tag:'GIFs'
    },
    {
        id: 11,
        creater1:client4,
        creater2:client5,
        creater3:client6,
        createrName:'Jacqueline Burns',
        title:'@ButterFly',
        product:product11,
        name:'Dodo hide the seek',
        value:'20.5 ETH',
        category:'games',
        date:'April 18, 2025 6:0:0',
        showDate:false
    },
    {
        id: 12,
        creater1:client7,
        creater2:client8,
        creater3:client9,
        createrName:'Rosaria Vargas',
        title:'@Princess',
        product:product12,
        name:'Pinky Ocean',
        value:'20.5 ETH',
        category:'music',
        date:'May 01, 2025 6:0:0',
        showDate:true
    },
    
    {
        id: 13,
        creater1:client10,
        creater2:client11,
        creater3:client12,
        createrName:'Rosaria Vargas',
        title:'@Princess',
        product:product13,
        name:'Rainbow Style',
        value:'20.5 ETH',
        category:'music',
        date:'May 01, 2025 6:0:0',
        showDate:true
    },
    {
        id: 14,
        creater1:client1,
        creater2:client2,
        creater3:client3,
        createrName:'Rosaria Vargas',
        title:'@Princess',
        product:product14,
        name:'Running Puppets',
        value:'20.5 ETH',
        category:'music',
        date:'May 01, 2025 6:0:0',
        showDate:true
    },
    {
        id: 15,
        creater1:client4,
        creater2:client5,
        creater3:client6,
        createrName:'Rosaria Vargas',
        title:'@Princess',
        product:product15,
        name:'Loop Donut',
        value:'20.5 ETH',
        category:'music',
        date:'May 01, 2025 6:0:0',
        showDate:true
    },
    {
        id: 16,
        creater1:client7,
        creater2:client8,
        creater3:client9,
        createrName:'Rosaria Vargas',
        title:'@Princess',
        product:product16,
        name:'This is Our Story',
        value:'20.5 ETH',
        category:'music',
        date:'May 01, 2025 6:0:0',
        showDate:true
    },
]

export const blogData = [
    {
        id:1,
        image:blog1,
        title:'Mindfulness Activities for Kids & Toddlers with NFT',
        tag:'Arts',
        date:'13th Sep, 2025',
        auther:'@callyjoe'
    },
    {
        id:2,
        image:blog2,
        title:'Save Thousands Of Lives Through This NFT',
        tag:'Illustration',
        date:'29th Nov, 2025',
        auther:' @kristyhoney'
    },
    {
        id:3,
        image:blog3,
        title:'A place where technology meets craftsmanship',
        tag:'Music',
        date:'29th Dec, 2025',
        auther:'@pandaone'
    },
    {
        id:4,
        image:blog4,
        title:'NFT Market - A Compact Trike with the Big Benefits',
        tag:'Video',
        date:'13th March, 2025',
        auther:'@streetboy'
    },
    {
        id:5,
        image:blog5,
        title:'Honoring Black History Month with Toddlers',
        tag:'Games',
        date:'5th May, 2025',
        auther:'@norsequeen'
    },
    {
        id:6,
        image:blog6,
        title:'Setting Intentions Instead of Resolutions for 2021',
        tag:'Memes',
        date:'19th June, 2025',
        auther:'@bigbull'
    },
    {
        id:7,
        image:blog7,
        title:'Clever Ways to Purchase Extraordinart Items',
        tag:'GIFs',
        date:'20th June, 2025',
        auther:'@princess'
    },
    {
        id:8,
        image:blog8,
        title:'How to Save Money on Baby Essentials for NFT',
        tag:'Video',
        date:'31st August, 2025',
        auther:'@crazyanyone'
    },
    {
        id:9,
        image:blog9,
        title:'Liki Trike - A Compact Trike with the Big Benefits',
        tag:'Music',
        date:'1st Sep, 2025',
        auther:'@angel'
    },
    {
        id:10,
        image:blog10,
        title:'NFT Market - A Compact the Big Benefits',
        tag:'Tech',
        date:'21th October, 2025',
        auther:'@cutiegirl'
    },
    {
        id:11,
        image:blog11,
        title:'Behind the Scenes of the creabik App',
        tag:'Arts',
        date:'19th June, 2025',
        auther:'@funnyguy'
    },
    {
        id:12,
        image:blog12,
        title:'Meet fennouni, Product Designer at GitHub',
        tag:'GIFs',
        date:'20th June, 2025',
        auther:'@butterfly'
    },
]

export const collectionData = [
    {
        image1:collection3,
        image2:collection1,
        image3:collection4,
        image4:collection10,
        client:client1,
        name:'Digital Arts',
        item:'27 Items'
    },
    {
        image1:collection2,
        image2:collection5,
        image3:collection6,
        image4:collection7,
        client:client2,
        name:'Sports',
        item:'27 Items'
    },
    {
        image1:collection8,
        image2:collection9,
        image3:collection11,
        image4:collection12,
        client:client3,
        name:'Photography',
        item:'27 Items'
    },
    {
        image1:collection4,
        image2:collection1,
        image3:collection10,
        image4:collection3,
        client:client4,
        name:'Illustrations',
        item:'27 Items'
    },
    {
        image1:collection5,
        image2:collection6,
        image3:collection2,
        image4:collection7,
        client:client5,
        name:'Animations',
        item:'27 Items'
    },
    {
        image1:collection11,
        image2:collection9,
        image3:collection8,
        image4:collection12,
        client:client6,
        name:'Virtual Reality',
        item:'27 Items'
    },
    {
        image1:collection12,
        image2:collection1,
        image3:collection4,
        image4:collection10,
        client:client7,
        name:'Digital Arts',
        item:'27 Items'
    },
    {
        image1:collection13,
        image2:collection5,
        image3:collection6,
        image4:collection7,
        client:client8,
        name:'Sports',
        item:'27 Items'
    },
    {
        image1:collection14,
        image2:collection9,
        image3:collection11,
        image4:collection12,
        client:client9,
        name:'Photography',
        item:'27 Items'
    },
    {
        image1:collection15,
        image2:collection1,
        image3:collection10,
        image4:collection3,
        client:client10,
        name:'Illustrations',
        item:'27 Items'
    },
    {
        image1:collection7,
        image2:collection6,
        image3:collection2,
        image4:collection5,
        client:client11,
        name:'Animations',
        item:'27 Items'
    },
    {
        image1:collection9,
        image2:collection11,
        image3:collection8,
        image4:collection12,
        client:client12,
        name:'Virtual Reality',
        item:'27 Items'
    },
]

export const services = [
    {
        icon:'uil uil-card-atm',
        title:'Set up your wallet',
        desc:'Start working with Superex NFTs that can provide everything'
    },
    {
        icon:'uil uil-bitcoin-circle',
        title:'Buy your collection',
        desc:'Start working with Superex NFTs that can provide everything'
    },
    {
        icon:'uil uil-wallet',
        title:'Add your NFTs',
        desc:'Start working with Superex NFTs that can provide everything'
    },
    {
        icon:'uil uil-layers',
        title:'Sell Your NFTs',
        desc:'Start working with Superex NFTs that can provide everything'
    },
]
export const slideOne = [
    item1,item3,item5,item7,item9,item11,item13,item1,item3,item5,item7,item9,item11,item13
]
export const slideTwo = [
    item2,item4,item6,item8,item10,item12,item14,item2,item4,item6,item8,item10,item12,item14
]

export const activityData = [
    {
        image:product15,
        title:'Digital Art Collection',
        name:'Started Following',
        name2:'@Panda',
        time:'1 hours ago',
        icon:'mdi mdi-account-check mdi-18px text-success'
    },
    {
        image:product14,
        title:'Skrrt Cobain Official',
        name:'Liked by',
        name2:'@ButterFly',
        time:'2 hours ago',
        icon:'mdi mdi-heart mdi-18px text-danger'
    },
    {
        image:product13,
        title:'Wow! That Brain Is Floating',
        name:'Liked by',
        name2:'@ButterFly',
        time:'2 hours ago',
        icon:'mdi mdi-heart mdi-18px text-danger'
    },
    {
        image:product12,
        title:'Our Journey Start',
        name:'Liked by',
        name2:'@CalvinCarlo',
        time:'5 hours ago',
        icon:'mdi mdi-format-list-bulleted mdi-18px text-warning'
    },
    {
        image:product11,
        title:'BitBears',
        name:'Liked by',
        name2:'@ButterFly',
        time:'8 hours ago',
        icon:'mdi mdi-heart mdi-18px text-danger'
    },
    {
        image:product10,
        title:'Little Kokeshi #13',
        name:'Liked by',
        name2:'@ButterFly',
        time:'1 hours ago',
        icon:'mdi mdi-heart mdi-18px text-danger'
    },
    {
        image:product9,
        title:'EVOL Floater',
        name:'Liked by',
        name2:'@CutieGirl',
        time:'13 hours ago',
        icon:'mdi mdi-account-check mdi-18px text-success'
    },
    {
        image:product8,
        title:'Smart Ape Club (SAC) - Limited Edition',
        name:'Liked by',
        name2:'@CalvinCarlo',
        time:'18 hours ago',
        icon:'mdi mdi-format-list-bulleted mdi-18px text-warning'
    },
    {
        image:product7,
        title:'THE SECRET SOCIETY XX #775',
        name:'Liked by',
        name2:'@CalvinCarlo',
        time:'23 hours ago',
        icon:'mdi mdi-format-list-bulleted mdi-18px text-warning'
    },
    {
        image:product6,
        title:'Create Your Own World',
        name:'Liked by',
        name2:'@ButterFly',
        time:'1 hours ago',
        icon:'mdi mdi-heart mdi-18px text-danger'
    },
]

export const bidsData = [
    {
        image:client1,
        title1:'2 WETH',
        title2:'0xe849fa28a...ea14',
        time:'6 hours ago'
    },
    {
        image:client8,
        title1:'0.001 WETH',
        title2:'VOTwear',
        time:'6 hours ago'
    },
    {
        image:client10,
        title1:'1.225 WETH',
        title2:'PandaOne',
        time:'6 hours ago'
    },
]

export const activityFilter = [
    {
        icon:'uil uil-wallet fs-6 me-1',
        title:'Purchased'
    },
    {
        icon:'uil uil-tag-alt fs-6 me-1',
        title:'Sales'
    },
    {
        icon:'uil uil-fire fs-6 me-1',
        title:'Burns'
    },
    {
        icon:'uil uil-heart fs-6 me-1',
        title:'Likes'
    },
    {
        icon:'uil uil-browser fs-6 me-1',
        title:'Bids'
    },
    {
        icon:'uil uil-users-alt fs-6 me-1',
        title:'Following'
    },
    {
        icon:'uil uil-list-ui-alt fs-6 me-1',
        title:'Listing'
    },
    {
        icon:'uil uil-music fs-6 me-1',
        title:'Music'
    },
    {
        icon:'uil uil-camera fs-6 me-1',
        title:'Video'
    },
    {
        icon:'uil uil-illustration fs-6 me-1',
        title:'Illustration'
    },
]

export const walletData = [
    {
        image:wallet1,
        name:'MetaMask',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md',
        tag:true
    },
    {
        image:wallet2,
        name:'Aave',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md'
    },
    {
        image:wallet3,
        name:'Airswap',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-warning p-5 rounded-md'
    },
    {
        image:wallet4,
        name:'Compound',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-danger p-5 rounded-md'
    },
    {
        image:wallet5,
        name:'DDEX',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-info p-5 rounded-md'
    },
    {
        image:wallet6,
        name:'Defi Saver',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md'
    },
    {
        image:wallet7,
        name:'DYDX',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md'
    },
    {
        image:wallet8,
        name:'IDEX',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-warning p-5 rounded-md'
    },
    {
        image:wallet9,
        name:'Kyber',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-danger p-5 rounded-md'
    },
    {
        image:wallet10,
        name:'Maker',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-info p-5 rounded-md'
    },
    {
        image:wallet11,
        name:'NUO',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md'
    },
    {
        image:wallet12,
        name:'PoolTogether',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-primary p-5 rounded-md'
    },
    {
        image:wallet13,
        name:'Sablier',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-warning p-5 rounded-md'
    },
    {
        image:wallet14,
        name:'Set',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-danger p-5 rounded-md'
    },
    {
        image:wallet15,
        name:'Uniswap',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-info p-5 rounded-md'
    },
    {
        image:wallet16,
        name:'Zerion',
        desc:'Learn about how to get the wallet and much more clicking',
        bgColor:'bg-gradient-secondary p-5 rounded-md'
    },
]

export const teamData = [
    {
        image:client1,
        name:'Calvin Carlo',
        possition:'Designer'
    },
    {
        image:client2,
        name:'Aliana Rosy',
        possition:'Designer'
    },
    {
        image:client3,
        name:'Micheal Carlo',
        possition:'Designer'
    },
    {
        image:client4,
        name:'Sofia Razaq',
        possition:'Designer'
    },
    {
        image:client5,
        name:'Jack John',
        possition:'Designer'
    },
    {
        image:client6,
        name:'Krista John',
        possition:'Designer'
    },
    {
        image:client7,
        name:'Roger Jackson',
        possition:'Designer'
    },
    {
        image:client8,
        name:'Johnny English',
        possition:'Designer'
    },
]

export const followerData = [
    {
        clientImg : client2,
        name:'CutieGirl',
        location:'Brookfield, WI',
        images:[product2, product4,product17,product7,product9,product18]
    },
    {
        clientImg : client13,
        name:'FunnyGuy',
        location:'Brookfield, WI',
        images:[product5, product20,product15,product11,product2,product18]
    },
    {
        clientImg : client3,
        name:'NorseQueen',
        location:'Brookfield, WI',
        images:[product10, product4,product12,product7,product9]
    },
    {
        clientImg : client4,
        name:'BigBull',
        location:'Brookfield, WI',
        images:[product13, product14,product15,product10]
    },
    {
        clientImg : client10,
        name:'KristyHoney',
        location:'Brookfield, WI',
        images:[product2, product4,product5,product7,product9,product11]
    },
    {
        clientImg : client12,
        name:'Princess',
        location:'Brookfield, WI',
        images:[product9, product14,product7,product13,product9,product10]
    },
]
export const recentPost = [
    {
        image:blog1,
        title:"Consultant Business",
        date:'13th March 2024'
    },
    {
        image:blog2,
        title:"Grow Your Business",
        date:'5th May 2024'
    },
    {
        image:blog3,
        title:"Look On The Glorious Balance",
        date:'19th June 2024'
    },
]

export const cloud = [
    'Business','Finance','Marketing','Fashion','Bride','Lifestyle','Travel','Beauty','Video','Audio'
]

export const helpcenterServices = [
    {
        icon:'uil uil-question-circle',
        title:'FAQs',
        desc:'Due to its widespread use as filler text for layouts, non-readability is of great importance.',
        link:'/helpcenter-faqs'
    },
    {
        icon:'uil uil-file-bookmark-alt',
        title:'Guides / Support',
        desc:'Due to its widespread use as filler text for layouts, non-readability is of great importance.',
        link:'/helpcenter-guides'
    },
    {
        icon:'uil uil-cog',
        title:'Support Request',
        desc:'Due to its widespread use as filler text for layouts, non-readability is of great importance.',
        link:'/helpcenter-support-request'
    },
]

export const accordionData = [
    {
        id:1,
        title:'How does it work ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:2,
        title:'Do I need a designer to use Created By Me ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:3,
        title:'What do I need to do to start selling ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    }, 
    {
        id:4,
        title:'What happens when I receive an order ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
]

export const guidenceData = [
    {
        title:'Getting started',
        subtitle:[
            'Pricing & availability','Booking settings','Responding to enquiries & requests','Snoozing or deactivating your listing'
        ]
    },
    {
        title:'Your calendar',
        subtitle:[
            'Deciding to purchase','List your space','Landing an experience or adventure','Top uses questions'
        ]
    },
    {
        title:'Your listings',
        subtitle:[
            'Updating your listing','Neighbourhoods','Listing photos & photography','Superex Plus','API-connected software'
        ]
    },
    {
        title:'How payouts work',
        subtitle:[
            'Getting paid','Adding payout info','Your payout status','Donations','Taxes'
        ]
    },
    {
        title:'Your reservations',
        subtitle:[
            'Superex safely','Superex Experiences and Adventures','Changing a reservation','Cancelling a reservation','Long-term reservations'
        ]
    },
    {
        title:'Reservation help',
        subtitle:[
            'Help with a reservation or guest','Guest cancellations',
        ]
    },
    {
        title:'Your account',
        subtitle:[
            'Your profile','Account security','Identification & verifications','Reviews','Superhost status'
        ]
    },
]

export const restrictions = [
        'Digital Marketing Solutions for Tomorrow','Our Talented & Experienced Marketing Agency','Create your own skin to match your brand','Digital Marketing Solutions for Tomorrow','Our Talented & Experienced Marketing Agency','Create your own skin to match your brand'
    
]