import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../components/navbar';
import BestCreater from '../../components/creater/best-creater';
import ProductOne from '../../components/product/product-one';
import LiveAuction from '../../components/live-auction';
import Blog from '../../components/blog';
import Footer from '../../components/footer';
import Card1 from '../../components/card-1';

import bg1 from '../../assets/images/bg/artists_1.png';
import bg2 from '../../assets/images/bg/artists_2.png';
import client1 from '../../assets/images/client/01.jpg';
import client2 from '../../assets/images/client/02.jpg';
import client3 from '../../assets/images/client/03.jpg';
import client4 from '../../assets/images/client/04.jpg';
import client5 from '../../assets/images/client/05.jpg';
import heroImg from '../../assets/images/items/30.jpg'; // '../../assets/images/gif/4.gif'

import blogBg from '../../assets/images/bg/art_gallery_1.png';
import item1 from '../../assets/images/items/board3_a.jpg';
import community from '../../assets/images/svg/community.png';
import united from '../../assets/images/svg/united.png';
import palette from '../../assets/images/default_user.gif';
import spidercake from '../../assets/images/items/spider_cake.jpg';
import { store } from '../../state';
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';

import defaultUserImg from '../../assets/images/default_user.gif';

import defaultAssetImg from '../../assets/images/svg/office-desk.svg';
import { ApplicationsService } from '../../services';
import { AssetService } from '../../services';
import { BlogService } from '../../services';
import AppConfig from '../../App.config';
import BlogCard from '../../components/blog-card';

const appSrv = new ApplicationsService();
const assetSrv = new AssetService();
const blogSrv = new BlogService();

export default function Index() {
    
    const [ assetData, setAssetData ] = useState(null);
    const [ blogData, setBlogData ] = useState(null);
    const [ assetMetadata, setAssetMetadata ] = useState(null);
    
    const [ creatorImages, setCreatorImages ] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    if (searchParams.get('id')) {
        navigate(`/work/${searchParams.get('id')}`);
    }
    let clientGroup = [
        client1,client2,client3
    ]
/*
    let [days, setDays] = useState(0);
    let [hours, setHours] = useState(0);
    let [minutes, setMinutes] = useState(0);
    let [seconds, setSeconds] = useState(0);
    let deadline = "December, 31, 2024";
//console.log(searchParams.get('id'));
    let getTime = () => {
      let time = Date.parse(deadline) - Date.now();
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
    */
    useEffect(()=>{
        const getAssetData = async () => {
            const data = await assetSrv.getNewest(3, null);
            setAssetData(data);
    
            if (data && data.assets) {
                const rawCreatorIds = data.assets.map(a => {
                    return a.creatorId;
                });
    
                const creatorIds = [];
    
                for(let i = 0; i < rawCreatorIds.length; i++) {
                    if (creatorIds.indexOf(rawCreatorIds[i]) < 0) {
                        creatorIds.push(rawCreatorIds[i]);
                    }
                }
    
                const metadataPromises = data.assets.map (ad => {
                    ad.url = ad.url.replace('http://192.168.4.148', 'https://createdbyme.io');
                    
                    return appSrv.getRemoteFile(ad.url)
                        .then(md => {
                            return {aid: ad.assetId, data: md};
                        });
                });
    
                const creatorPromises = creatorIds.map(cid => {
                    return appSrv.getApplicationFile(cid, AppConfig.appVars.creatorProfileImg)
                        .then(resp => {
                            return {cid: cid, data: resp};
                        });
                });
    
                const promises = [
                    Promise.all(creatorPromises).then(resp => {
                        let ci = {};
                        
                        for (let i = 0; i < resp.length; i++) {
                            Object.defineProperty(ci, `_${resp[i].cid}`, {value:resp[i].data});
                        }
    
                        setCreatorImages(ci);
                    }),
                    Promise.all(metadataPromises).then(resp => {
                        let md = {};
                        
                        for (let i = 0; i < resp.length; i++) {
                            resp[i].data.image = resp[i].data.image.replace('http://192.168.4.148', 'https://createdbyme.io');
                            Object.defineProperty(md, `_${resp[i].aid}`, {value:resp[i].data});
                        }
                        
                        setAssetMetadata(md);
                    })
                ];
    
                await Promise.all(promises);
            }
          }
  
        const getBlogData = async () => {
            const userId = store.state.user.loggedIn ? store.state.user.getId() : -1;
            const data = await blogSrv.findAll(userId, 3, null, null, null, null);

            setBlogData(data.blogs);
        }

        getAssetData();
        getBlogData();
    }, [])

  return (
    <>
    <Navbar navlight={false}/>

    <section className="bg-half-120 w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
        <div className="bg-overlay bg-gradient-primary opacity-5"></div>
        <div className="container d-flex justify-content-center">
            <div className="col-lg-8 title-heading text-center">
                <h3 className="heading fw-semibold sub-heading text-white title-dark">A space where artists can establish their brand and tell their story</h3>
                <h5 className="heading fw-semibold sub-heading text-white title-dark mt-5">Newest Art</h5>
                <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                {assetData?.assets.map((item,index)=>{
                    return(
                        <div className="col mt-4 pt-2" key={index}>
                            <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3" style={{backgroundColor: 'black'}}>
                            <div className="d-flex justify-content-between">
                                <div className="img-group">
                                    <Link to="/creator-profile" className="user-avatar">
                                        {(creatorImages && creatorImages[`_${item.creatorId}`] !== null ) && (
                                        <img src={creatorImages[`_${item.creatorId}`]} alt="creator" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                        )}
                                        {(creatorImages && creatorImages[`_${item.creatorId}`] === null)  && (
                                        <img src={defaultUserImg} alt="" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                        )}
                                    </Link>
                                </div>
                                
                                <span className="like-icon shadow-sm"><Link to="#" className="text-muted icon"><i className="mdi mdi-18px mdi-heart mb-0"></i></Link></span>
                            </div>

                            <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                                {(assetMetadata && assetMetadata[`_${item.assetId}`] !== null) && (
                                <Link to={`/work/${item.assetId}`}><img src={assetMetadata[`_${item.assetId}`].image} className="img-fluid" alt=""/></Link>
                                )}
                                {(assetMetadata && assetMetadata[`_${item.assetId}`] === null) && (
                                <Link to={`/work/${item.assetId}`}><img src={defaultAssetImg} className="img-fluid" alt=""/></Link>
                                )}
                                <div className="position-absolute top-0 start-0 m-2">
                                    <Link to="#" className="badge badge-link bg-primary">{item.tag}</Link>
                                </div>

                                <div className="position-absolute top-0 end-0 m-2">
                                    <Link to={`/work/${item.assetId}`} className="btn btn-pills btn-icon"><i className="uil uil-shopping-cart-alt"></i></Link>
                                </div>
                            </div>

                            <div className="card-body content position-relative p-0 mt-3">
                                <Link to={`/work/${item.assetId}`} className="title text-dark h6">{item.name}</Link>

                                <div className="d-flex justify-content-between mt-2">
                                    
                                    <small className="rate fw-bold">
                                    {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.value) && (
                                        <>
                                        {assetMetadata[`_${item.assetId}`].properties?.value} {assetMetadata[`_${item.assetId}`].properties?.currency}
                                        </>
                                    )}
                                    </small>
                                    
                                    <small className="text-dark fw-bold">
                                    {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.copyId) && (
                                        <>
                                        {assetMetadata[`_${item.assetId}`].properties.copyId} out of {assetMetadata[`_${item.assetId}`].properties.numberOfCopies}
                                        </>
                                    )}
                                    </small>
                                    
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">            
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section className="section">

        <div className="container-fluid mt-100 mt-60">
            <div className="row px-0">
                <div className="bg-half-100 bg-gradient-primary">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title text-white title-dark mb-4">News</h4>
                                    <p className="text-white-50 para-desc mb-0 mx-auto">News, Announcments, etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4"> 
                            <div className="col" >
                                {(store.state.user.getCreatorId() === '' || store.state.user.getCreatorId() === null) && (
                                <Card1 
                                actionTarget = "/become-creator" 
                                actionTitle = "Learn More" 
                                imgUrl = {palette} 
                                title = "Become a Creator" 
                                description = "Build your brand, bring your creations to others."
                                />
                                )}
                                {(store.state.user.getCreatorId() !== '' && store.state.user.getCreatorId() !== null) && (
                                <Card1 
                                actionTarget = "/register-work" 
                                actionTitle = "Add Work" 
                                imgUrl = {palette} 
                                title = "Add Art" 
                                description = "Register an original work, created by you."
                                />
                                )}
                            </div>
            
                            <div className="col">
                                <Card1 
                                actionTarget = "/aboutus" 
                                actionTitle = "Read More" 
                                imgUrl = {community} 
                                title = "Join our community" 
                                description = ""
                                />
                            </div>
                            
                            <div className="col">
                                <Card1 
                                actionTarget = "/aboutus" 
                                actionTitle = "Read More" 
                                imgUrl = {united} 
                                title = "Learn more about CreatedByMe" 
                                description = "We are a huge marketplace dedicated to connecting great artists"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-4">Artists' Stories</h4>
                        <p className="text-muted para-desc mb-0 mx-auto">Latest information, articles and stories from our artists/</p>
                    </div>
                </div>
            </div>
            <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              {blogData?.map((item,index)=>{
                return(
                    <div className="col mt-4 pt-2" key={index}>
                        <BlogCard
                            titleImage={item.titleImage != null ? item.titleImage : blogBg}
                            topic={item.topic}
                            title={item.title}
                            displayName="xactant"
                            creatorId={item.creatorId}
                            friendlyName={item.searchName}
                            updatedDate={item.updatedAt}
                        ></BlogCard>
                    </div>
                )
              })}
            </div>
           
        </div>
    </section>
    <Footer/>

    </>
  )
}
