import Badge from "../../components/badge";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from "../../components/footer";
import cbg from '../../assets/images/work/1.jpg';
import {ApplicationsService, AssetService, CreatorService, IpfsService} from '../../services';
import { useNavigate } from "react-router-dom";
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import AppConfig from '../../App.config';
import Dropzone from 'react-dropzone';
import { AssetImage } from "../../models/asset-image";
import { AssetMetadata } from "../../models/asset-metadata";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();
const ipfsSrv = new IpfsService();
const assetSrv = new AssetService();

export default function RegisterWork() {
    let [creatorData, setCreatorData] = useState(null);
    let [creatorBgImg, setCreatorBgImg] = useState(cbg);
    let [assetId, setAssetId] = useState('');
    let [assetName, setAssetName] = useState('');
    let [assetDescription, setAssetDescription] = useState('');
    let [assetSeries, setAssetSeries] = useState('');
    let [assetTags, setAssetTags] = useState();
    let [formIsValid, setFormIsValid] = useState(false);
    let [addingTag, setAddingTag] = useState(false);
    let [proposedTag, setProposedTag] = useState('');
    let [proposedQid, setProposedQid] = useState('');
    let [proposedImage, setProposedImage] = useState(new AssetImage());
    let [addingImage, setAddingImage] = useState(false);
    let [assetImages, setAssetImages] = useState([]);
    let [seriesData, setSeriesData] = useState([]);

    const navigate = useNavigate();

    useEffect(()=>{
        setAssetTags([]);
        const getCreatorRecord = async (id) => {
            if (id) {
                await creatorSrv.getCreator(id).then(rec => {
                    setCreatorData(rec);
                });
            }
        };

        const retrieveSeriesList = async (cid) => {
            const sl = await creatorSrv.getSeriesList(cid, 250, null, null, null);
            
            if (sl && sl.series.length > 0) {
                setSeriesData(sl.series);
            }
        }

        if (!store.state.user.loggedIn) {
            navigate('/login');
        }
        
        if(store.state.user.getCreatorId() === '') {
            navigate('/');
        }

        const creatorId = store.state.user.getCreatorId();
        toast.info(`CreatorId: ${creatorId}`);
        getCreatorRecord(creatorId);
        getCreatorBgImage(creatorId);
        retrieveSeriesList(creatorId);        

    }, []);

    const onDrop = async (acceptedFiles) => {
        console.log(acceptedFiles);

        if (acceptedFiles && acceptedFiles.length > 0) {
            const img = proposedImage;

            // If a current file laded - remove it.
            if (img.qid) {
                await ipfsSrv.removeQid(img.qid);
            }
            const resp = await ipfsSrv.upload(acceptedFiles[0]);

            console.log(`file upload resp: ${resp}`);

            img.qid = resp;

            setProposedQid(resp);
            setProposedImage(img);
        }
    }

    const getCreatorBgImage = async (creatorId) => {
        let upi = null;

        upi = await appSrv.getApplicationFile(creatorId, AppConfig.appVars.creatorBackground);

        if (upi) {
            setCreatorBgImg(upi);
        }
    }

    const handleDeleteBadge = (val) => {
        if(assetTags.indexOf(val) >= 0) {
            const updated = assetTags.filter((t) => t !== val);
            setAssetTags(updated);
        }
    }

    const handleAddImage = async () => {
        await assetSrv.addQid(proposedImage);

        const imgs = assetImages;
        imgs.push(proposedImage);

        setAssetImages(imgs);
        setAddingImage(false);
        setProposedImage(new AssetImage());
        setProposedQid('');
        formValid();
    }

    const handleCancelAddImage = async () => {
        if (proposedImage.qid) {
            await ipfsSrv.removeQid(proposedImage.qid);
        }
        setAddingImage(false);
        setProposedQid('');
        setProposedImage(new AssetImage());
    }

    const handleAddBadge = () => {
        if (proposedTag !== '') {
            const proposed = proposedTag.split(',');
            const tags = assetTags;
            for(let i = 0; i < proposed.length; i++) {
                if(tags.indexOf(proposed[i].trim()) < 0) {
                    tags.push(proposed[i].trim());
                }
            }

            setAssetTags(tags);
        }

        setAddingTag(false);
        setProposedTag('');
        setProposedQid('');
    }

    const handleUpdateProposedImage = (element, val) => {
        const img = proposedImage;
        img.assetId = assetId;

        if (img.label === '' || img.label === null) {
            img.label = 'image';
        }

        img[element] = val;
        setProposedImage(img);
    }

    const handleRemoveImage = async (img) => {
        if (img.qid) {
            await ipfsSrv.removeQid(img.qid);
        }

        //const resp = await assetSrv.removeQid(img.assetId, img.qid);
        //console.log(`assetSrv.removeQid resp: ${JSON.stringify(resp)}`);
        const images = assetImages.filter(ai => {
            return ai.qid !== img.qid;
        });

        setAssetImages(images);
        formValid();
    }

    const handleSetAssetId = (v) => {
        setAssetId(v);
        formValid();
    }

    const handleSetAssetName = (v) => {
        setAssetName(v);
        formValid();
    }

    const handleSetAssetDescription = (v) => {
        setAssetDescription(v);
        formValid();
    }

    const formValid = () => {
        let v = creatorData !== null &&
            assetId !== '' &&
            assetName !== '' &&
            assetDescription !== '' &&
            assetImages.length > 0;

        if (assetName.length > 64) {
            v = false;
        }

        if (assetDescription.length > 1024) {
            v = false;
        }

        setFormIsValid(v);
    }

    const handleSubmit = async () => {
        const mdResp = await storeMetadata();
        
        if (mdResp.qid && mdResp.hash) {
            const mdUrl = `${AppConfig.ipfsRetrievePath}/${mdResp.qid}`;
            const resp = await assetSrv.registerAsset(creatorData.creatorId, assetId, assetName, assetDescription, assetSeries,creatorData.creatorId, mdUrl, mdResp.hash, assetTags);
            
            if (resp.status === 201) {
                toast.success('Asset registered successfully.');
            } else {
                toast.error(`Register failed with: ${resp.status}`);
            }
        } else {
            toast.warning('Did not register asset since saving metadata failed.');
        }
    }

    const storeMetadata = async () => {
        let resp = {
            qid: null,
            hash: null
        };

        if (formIsValid) {
            const metadata = new AssetMetadata();
            metadata.description = assetDescription;
            metadata.name = assetName;
            metadata.image = `${AppConfig.ipfsRetrievePath}/${assetImages[0].qid}`;
            metadata.properties = {
                seriesId: assetSeries
            };

            console.log(`metadata: ${JSON.stringify(metadata)}`);
            const ipfsResp = 
                await ipfsSrv.uploadObjectAsJson(`${creatorData.displayName}-metadata`, metadata);
            
            if (ipfsResp !== null) {
                toast.success('Metadata written to IPFS.');
                resp.qid = ipfsResp;

                const hashResp = await assetSrv.hashDocument(JSON.stringify(metadata));

                resp.hash = await hashResp.data;

                const item = {
                    assetId: assetId,
                    qid: ipfsResp,
                    label: 'metadata',
                    description: `Metadata for ${assetId}`
                }
                
                await assetSrv.addQid(item);

                toast.success ('Metadata stored successfully.');
            }
        }

        return resp;
    }

    const generateAssetUUID = async () => {
        const uuid = await appSrv.generateUuid(creatorData.creatorId, store.state.user.getId());
        
        setAssetId(uuid);
    };

    const handleSelectSuggestion = (e) => {
        const vals = proposedTag === '' ? [] : proposedTag.split(',');
        if (vals.indexOf(e) < 0) {
            vals.push(e.trim());
        }
        setProposedTag(vals.join(', '));
    }
    
    const handleAssetIdKeyDown = (e) => {
        // Make sure enter key character does not trigger a page re-load. This
        // can happen when an RFID reader in text mode is used to scan an id into 
        // the field.
        if(e.keyCode === 13) {
            e.preventDefault();
        }
    }

    return (
        <>
        <Navbar navlight={true}/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url("${creatorBgImg}")`, backgroundPosition:'bottom'}}>
          <div className="bg-overlay bg-gradient-overlay-2"></div>
          <div className="container">
              <div className="row mt-5 justify-content-center">
                  <div className="col-12">
                      <div className="title-heading text-center">
                          <h5 className="heading fw-semibold sub-heading text-white title-dark">Add Work</h5>
                          <p className="text-white-50 para-desc mx-auto mb-0">Register a new work</p>
                      </div>
                  </div>
              </div>
    
              <div className="position-middle-bottom">
    
                  <nav aria-label="breadcrumb" className="d-block">
                      <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Add Work</li>
                      </ul>
                  </nav>
              </div>
          </div>
      </section>
      <div>

      </div>
      <div className="position-relative">            
          <div className="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
          </div>
      </div>
      <section className="section pt-2">
        <div className="container">
            <div className="row justify-content-center mt-4 pt-2">
                <div className="col-lg-10">
                    <div className="card p-4 rounded-md shadow">
                        <div className="row">
                            <div className="mt-4 mt-sm-0">
                                <div className="ms-md-4">
                                    <h3>{creatorData?.displayName}</h3>
                                    <form >
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <label className="form-label">
                                                    Asset Id
                                                    <i className="mdi mdi-vector-link display-name-tooltip mx-2" />
                                                    <Tooltip anchorSelect=".display-name-tooltip" place="top">
                                                        This value is stored on chain.
                                                    </Tooltip>
                                                </label>
                                                <small className="text-muted d-block">RFID or Unique ID</small>
                                                <div className="iconed-input d-flex flex-row">
                                                    <input 
                                                        name="assetid" 
                                                        id="assetid" 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="0430F601110603" 
                                                        onKeyDown={(e) => handleAssetIdKeyDown(e)}
                                                        onChange={(e) => handleSetAssetId(e.target.value)} 
                                                        value={assetId} />
                                                        <i className="uil uil-object-group fs-5 generate-tooltip" onClick={generateAssetUUID}/>
                                                        <Tooltip anchorSelect=".generate-tooltip" place="top">
                                                            Generate a UUID.
                                                        </Tooltip>
                                                </div>
                                                <span className={"warning-text " + (assetId === '' ? "" : "hidden")}>required</span>
                                            </div>
            
                                            <div className="col-12 mb-4">
                                                <label className="form-label">
                                                    Work Title
                                                    <i className="mdi mdi-vector-link asset-name-tooltip mx-2" />
                                                    <Tooltip anchorSelect=".asset-name-tooltip" place="top">
                                                        This value is stored on chain.
                                                    </Tooltip>
                                                </label>
                                                <small className="text-muted d-block">Title / name of this work</small>
                                                <div className=" d-flex flex-column">
                                                    <input 
                                                        name="name" 
                                                        id="name" 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Art Title" 
                                                        onChange={(e) => handleSetAssetName(e.target.value)} 
                                                        value={assetName} />
                                                    
                                                    <span className={"warning-text " + (assetName === '' ? "" : "hidden")}>required</span>
                                                    <span className={"warning-text " + (assetName.length > 64 ? "" : "hidden")}>limit 64 characters</span>
                                                </div>
                                            </div>

                                            <div className="col-12 mb-4">
                                                <label className="form-label">Description
                                                    <i className="mdi mdi-vector-link story-tooltip mx-2" />
                                                    <Tooltip anchorSelect=".story-tooltip" place="top">
                                                        This value is stored on chain.
                                                    </Tooltip>
                                                </label>
                                                <small className="text-muted d-block">This is a detailed description of the Asset.</small>
                                                <div className="d-flex flex-column">
                                                    <textarea 
                                                        name="comments" 
                                                        id="comments" 
                                                        rows="3" 
                                                        className="form-control" 
                                                        placeholder="Cutting board made from maple and paduk in a brick pattern."
                                                        value={assetDescription}
                                                        onChange={(e) => handleSetAssetDescription(e.target.value)}>
                                                    </textarea>
                                                    { assetDescription === '' && (
                                                        <span className="warning-text ">required</span>
                                                    )}
                                                    <span className={"warning-text " + (assetDescription.length > 1024 ? "" : "hidden")}>limit 1024 characters</span>
                                                </div>
                                            </div>
                                            
                                            <div className="col-12 mb-4">
                                                <label className="form-label">
                                                    Collection Series
                                                    <i className="mdi mdi-vector-link series-tooltip mx-2" />
                                                    <Tooltip anchorSelect=".series-tooltip" place="top">
                                                        This value is stored on chain.
                                                    </Tooltip>
                                                </label>
                                                <small className="text-muted d-block">The collection this work is associated with (optional)</small>
                                                <div className="d-flex flex-row">
                                                    <select 
                                                        className="form-control"
                                                        onChange={(e) => setAssetSeries(e.target.value)}
                                                        placeholder="none">
                                                            <option value="">none</option>
                                                            {seriesData.map ( (sd, idx) => {
                                                                return (
                                                                    <option key={`opt_${idx}`} value={sd.seriesId}>{sd.seriesId}</option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label className="form-label">
                                                    Descriptive Badges
                                                    <i className="mdi mdi-vector-link badges-tooltip mx-2" />
                                                    <Tooltip anchorSelect=".badges-tooltip" place="top">
                                                        These values is stored on chain.
                                                    </Tooltip>
                                                </label>
                                                <div className="badge-container m-3">
                                                    {assetTags?.map((t, idx) => {
                                                        return (
                                                            <Badge name={`bage_${idx}`} id={`bage_${idx}`} key={`bage_${idx}`} title={t}  deleteCallback={() => handleDeleteBadge(t)}/>
                                                        );
                                                    }) }
                                                </div>
                                                {addingTag && (
                                                <div>
                                                    <p className="small">A term that describes the work and can be used to search for / filter works. Some examples are "Art", "Abstract", "For Sale", etc.</p>
                                                    <div className="d-flex flex-row">

                                                        <div className="inline">
                                                            <label className="form-label">
                                                                Suggestions
                                                                <i className="mdi mdi-help-circle-outline badge-select-tooltip mx-2" />
                                                                <Tooltip anchorSelect=".badge-select-tooltip" place="top">
                                                                    Multiple badges can be selected.
                                                                </Tooltip>
                                                            </label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(e) => handleSelectSuggestion(e.target.value)}
                                                                placeholder="Art  ▼"
                                                                multiple>
                                                                <optgroup label="Major Categories">
                                                                    <option value="Art">Art</option>
                                                                    <option value="Decorative Art">Decorative Art</option>
                                                                    <option value="Fine Art">Fine Art</option>
                                                                    <option value="Functional Art">Functional Art</option>
                                                                    <option value="Plastic Art">Pastic Art</option>
                                                                    <option value="Musical Instruments">Musical Instruments</option>
                                                                    <option value="Woodworking">Woodworking</option>
                                                                </optgroup>
                                                                <hr />
                                                                <optgroup label="Art Categories">
                                                                    <option value="Calligraphy">Calligraphy</option>
                                                                    <option value="Ceramics">Ceramics</option>
                                                                    <option value="Drawing">Drawing</option>
                                                                    <option value="Glass Work">Glass Work</option>
                                                                    <option value="Jewelry">Jewelry</option>
                                                                    <option value="Mosaic">Mosaic</option>
                                                                    <option value="Painting">Painting</option>
                                                                    <option value="Printmaking">Printmaking</option>
                                                                    <option value="Relief">Relief</option>
                                                                    <option value="Sculpture">Sculpture</option>
                                                                    <option value="Tapestry">Tapestry</option>
                                                                </optgroup>
                                                                <hr />
                                                                <optgroup label="Art Descriptors">
                                                                    <option value="Abstract">Abstract</option>
                                                                    <option value="Genre Art">Genre Art</option>
                                                                    <option value="Historical">Historical</option>
                                                                    <option value="Impressionism">Impressionism</option>
                                                                    <option value="Landscape">Landscape</option>
                                                                    <option value="Maritime">Maritime</option>
                                                                    <option value="Nature">Nature</option>
                                                                    <option value="Painterly">Painterly</option>
                                                                    <option value="Photorealism">Photorealism</option>
                                                                    <option value="Pop Art">Pop Art</option>
                                                                    <option value="Portrait">Portrait</option>
                                                                    <option value="Realism">Realism</option>
                                                                    <option value="Sacred">Sacred</option>
                                                                    <option value="Seascape">Seascape</option>
                                                                    <option value="Still Life">Still Life</option>
                                                                    <option value="Surrealism">Surrealism</option>
                                                                    <option value="Urban Landscape">Urban Landscape</option>
                                                                </optgroup>
                                                                <hr />
                                                                <optgroup label="Musical Instrument Descriptors">
                                                                    <option value="Flute">Flute</option>
                                                                    <option value="Stringed">Stringed</option>
                                                                    <option value="Electric">Electric</option>
                                                                    <option value="Guitar">Guitar</option>
                                                                    <option value="Jazz Bass">Jazz Bass</option>
                                                                    <option value="Percision Bass">Percision Bass</option>
                                                                </optgroup>
                                                            </select>
                                                        </div>
                                                        <div className="inline">
                                                            <label className="form-label">
                                                                Badge Title(s)
                                                                <i className="mdi mdi-help-circle-outline badge-title-tooltip mx-2" />
                                                                <Tooltip anchorSelect=".badge-title-tooltip" place="top">
                                                                    Enter multiple titles seperated by commas.
                                                                </Tooltip>
                                                            </label>
                                                            <input 
                                                                className="form-control"
                                                                onChange={(e) => setProposedTag(e.target.value)}
                                                                placeholder="Other"
                                                                value={proposedTag} />
                                                        </div>
                                                        <button 
                                                            type="button" 
                                                            id="addTagBtn" 
                                                            name="addTagBtn" 
                                                            onClick={() => handleAddBadge()}
                                                            className={"btn btn-primary rounded-md" }>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                                {!addingTag && (
                                                <button 
                                                    type="button" 
                                                    id="toggleAddTagBtn" 
                                                    name="toggleAddTagBtn" 
                                                    onClick={() => setAddingTag(true)}
                                                    className={"btn btn-primary rounded-md mx-2" }>
                                                    Add Badge
                                                </button>
                                                )}    
                                            </div>

                                            {assetId && (
                                            <div className="col-lg-12 mt-3">
                                                <label className="form-label">Images and Documents
                                                    <i className="docs-tooltip small mx-2" >ipfs</i>
                                                    <Tooltip anchorSelect=".docs-tooltip" place="top">
                                                        These documents are stored on <a href="https://en.wikipedia.org/wiki/InterPlanetary_File_System">IPFS</a>
                                                    </Tooltip>
                                                </label>
                                                <small className="text-muted d-block">Images and documents that show your work, prove authenticity and provide detailed descriptions. First item becomes the default shown with your work.</small>
                                                <div className="col-lg-12 mt-3">
                                                {assetImages.map((ai, idx) => {
                                                    return (
                                                        <div className="d-flex flex-column flex-md-row"
                                                            id={`ai_${idx}`}
                                                            name={`ai_${idx}`}
                                                            key={`ai_${idx}`}>
                                                            <div className="d-flex align-items-center mt-3 me-3">
                                                                <img 
                                                                    alt={ai.qid}
                                                                    src={`${AppConfig.ipfsRetrievePath}/${ai.qid}`} 
                                                                    width="64" />
                                                            </div>
                                                            <div className="d-flex align-items-center me-3">
                                                                <label className="form-label">{ai.label}</label>
                                                            </div>
                                                            <div className="d-flex align-items-center flex-fill">
                                                                <label className="form-label">{ai.description}</label>
                                                            </div>
                                                            <button 
                                                                type="button" 
                                                                id="removeImgBtn" 
                                                                name="removeImgBtn" 
                                                                onClick={() => handleRemoveImage(ai)}
                                                                className={"d-flex align-items-center btn btn-primary rounded-md mt-3" }>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                                </div>
                                                {addingImage && (
                                                <div className="col-lg-12 mt-3">
                                                    <p classname="small"></p>
                                                    <div className="d-flex flex-column flex-md-row">
                                                        <div className="inline mt-3 me-3">
                                                        {(proposedQid !== null && proposedQid !== '') && (
                                                            <img 
                                                                alt={proposedImage.qid}
                                                                src={`${AppConfig.ipfsRetrievePath}/${proposedImage.qid}`}
                                                                width="64" />
                                                        )}
                                                        {(proposedQid === null || proposedQid === '') && (
                                                        <Dropzone onDrop={onDrop}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div {...getRootProps()} className="load-img-dz">
                                                            <input {...getInputProps()}/>
                                                             
                                                            </div>
                                                        )}
                                                        </Dropzone>
                                                        )}
                                                        </div>
                                                        <div className="inline me-3">
                                                            <label className="form-label">
                                                                Document Type
                                                                <i className="mdi mdi-help-circle-outline label-select-tooltip mx-2" />
                                                                <Tooltip anchorSelect=".label-select-tooltip" place="top">
                                                                    A label that describes the type of document you are uploading.
                                                                </Tooltip>
                                                            </label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(e) => handleUpdateProposedImage('label', e.target.value)}
                                                                placeholder="Image  ▼"
                                                                >
                                                                <option value="img">Image</option>
                                                                <option value="text">Text</option>
                                                                <option value="pdf">PDF</option>
                                                                <option value="json">JSON</option>
                                                                <option value="xml">XML</option>
                                                            </select>
                                                        </div>

                                                        <div className="flex-fill">
                                                            <label className="form-label">
                                                                Description
                                                                <i className="mdi mdi-help-circle-outline img-desc-title-tooltip mx-2" />
                                                                <Tooltip anchorSelect=".img-desc-title-tooltip" place="top">
                                                                    Description of the document being uploaded.
                                                                </Tooltip>
                                                            </label>
                                                            <input 
                                                                className="form-control"
                                                                onChange={(e) => handleUpdateProposedImage('description', e.target.value)}
                                                                placeholder="Other"
                                                                value={proposedImage.description} />
                                                        </div>
                                                    </div>

                                                    <button 
                                                        type="button" 
                                                        id="addImgBtn" 
                                                        name="addImgBtn" 
                                                        onClick={() => handleAddImage()}
                                                        className={"btn btn-primary rounded-md mt-3" }>
                                                        Add
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        id="cancelAddImgBtn" 
                                                        name="cancelAddImgBtn" 
                                                        onClick={() => handleCancelAddImage()}
                                                        className={"btn btn-primary rounded-md ms-3 mt-3" }>
                                                        Cancel
                                                    </button>
                                                
                                                </div>
                                                )}
                                                { (assetImages.length < 1) && (
                                                    <div className="warning-text ">at least one is required</div>
                                                )}
                                                {!addingImage && (
                                                <button 
                                                    type="button" 
                                                    id="toggleAddImgBtn" 
                                                    name="toggleAddImgBtn" 
                                                    onClick={() => setAddingImage(true)}
                                                    className={"btn btn-primary rounded-md mt-3" }>
                                                    Add Image / Document
                                                </button>
                                                )}   
                                            </div>
                                            )}

                                            <div className="col-lg-12 mt-5">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary rounded-md"
                                                    onClick={handleSubmit}
                                                    disabled={!formIsValid}
                                                >Register</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      </section>
      <Footer/>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
    )
    
}