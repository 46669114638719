function getSocialMediaClass(mediaType) {
    let result = '';

    switch(mediaType) {
        case 'facebook':
            result = 'uil uil-facebook-f';
            break;
        case 'instagram':
            result = 'uil uil-instagram';
            break;
        case 'website':
            result = 'uil uil-globe';
            break;
        case 'twitter':
            result = 'uil uil-twitter';
            break;
        default:
            result = 'uil uil-flower';
            break;
    }

    return result;
}

/*
const SocialMediaHelper = {
    getSocialMediaClass: getSocialMediaClass
}
*/

export const SocialMediaHelper = {
    getSocialMediaClass: getSocialMediaClass
};