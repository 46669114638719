import { BaseService } from './base.service';
import AppConfig from '../App.config';

export class BlogService extends BaseService {
    
  async getBlogById(blogId) {
    const url = `${AppConfig.blogApi}/getBlogById/${blogId}`;

    let queryParams = {};

    const resp = await this.executeGet(url, queryParams);
    console.log(`getBlogById returned: ${JSON.stringify(resp)}`)

    return resp.data;
  }

  async getByFriendlyName(creatorName, name) {
    const url = `${AppConfig.blogApi}/getByFriendlyName/${creatorName}/${name}`;

    let queryParams = {};

    const resp = await this.executeGet(url, queryParams);
    console.log(`getByFriendlyName returned: ${JSON.stringify(resp)}`)

    return resp.data;
  }

  async isTitleAvailable(creatorId, name) {
    const url = `${AppConfig.blogApi}/isTitleAvaialble/${creatorId}/${name}`;

    let queryParams = {};

    const resp = await this.executeGet(url, queryParams);
    console.log(`isTitleAvailable returned: ${JSON.stringify(resp)}`)

    return resp.data;
  }

  async findAll(
    userId,
    pageSize,
    startKey = null,
    endKey = null,
    topic = null,
    cursor = null,
  ) {
    const url = `${AppConfig.blogApi}/find`;

    let queryParams = {};
    if (userId) queryParams['userId'] = userId;
    if (pageSize) queryParams['pageSize'] = pageSize;
    if (startKey) queryParams['startKey'] = startKey;
    if (endKey) queryParams['endKey'] = endKey;
    if (topic) queryParams['topic'] = topic;
    if (cursor) queryParams['cursor'] = cursor;

    const resp = await this.executeGet(url, queryParams);
    console.log(`Find all returned: ${JSON.stringify(resp)}`)

    return resp.data;
  }

  async findByCreator(
    userId,
    creatorId,
    pageSize,
    startKey = null,
    endKey = null,
    topic = null,
    cursor = null,
  ) {
    const url = `${AppConfig.blogApi}/findByCreator/${creatorId}`;

    let queryParams = {};
    if (userId) queryParams['userId'] = userId;
    if (pageSize) queryParams['pageSize'] = pageSize;
    if (startKey) queryParams['startKey'] = startKey;
    if (endKey) queryParams['endKey'] = endKey;
    if (topic) queryParams['topic'] = topic;
    if (cursor) queryParams['cursor'] = cursor;

    const resp = await this.executeGet(url, queryParams);
    console.log(`Find by creator returned: ${JSON.stringify(resp)}`)

    return resp.data;
  }

  async addBlogEntry(
    creatorId,
    title,
    topic,
    hashTags,
    summary,
    data,
    level = 0) {
    const url = `${AppConfig.blogApi}/create`;

    const body = {
        "creatorId": creatorId,
        "title": title,
        "topic": topic,
        "hashTags": hashTags,
        "summary": summary,
        "data": data,
        "level": level
    };

    const resp = await this.executePost(url, body);

    return resp;
  }

  async updateBlogEntry(
    blogId,
    creatorId,
    title,
    topic,
    hashTags,
    summary,
    data,
    level = 0) {
    const url = `${AppConfig.blogApi}/update`;

    const body = {
        "blogId": blogId,
        "creatorId": creatorId,
        "title": title,
        "topic": topic,
        "hashTags": hashTags,
        "summary": summary,
        "data": data,
        "level": level
    };

    const resp = await this.executePost(url, body);

    return resp;
  }

  async addBlogComment(
    blogId,
    userId,
    emailAddress,
    commentText) {
    const url = `${AppConfig.blogApi}/addComment`;

    const body = {
        "blogId": blogId,
        "userId": userId,
        "emailAddress": emailAddress,
        "commentText": commentText
    };

    console.log(`addBlogComment: ${url} ${JSON.stringify(body)}`);
    const resp = await this.executePost(url, body);

    return resp;
  }

  async updateBlogEComment(
    id,
    blogId,
    userId,
    emailAddress,
    commentText) {
    const url = `${AppConfig.blogApi}/uodateComment`;

    const body = {
        "id": id,
        "blogId": blogId,
        "userId": userId,
        "emailAddress": emailAddress,
        "commentText": commentText
    };

    const resp = await this.executePost(url, body);

    return resp;
  }
}