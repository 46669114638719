import { BaseService } from './base.service';
import AppConfig from '../App.config';
import BigNumber from '../assets/js/big-number';

export class ApplicationsService extends BaseService {
    async getPricePackage(id) {
        const url = `${AppConfig.appApi}/pricePackage/${id}`;

        let queryParams = {};

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get price package returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getActivePricePackages () {
        const url = `${AppConfig.appApi}/activePricePackages/ASC`;

        let queryParams = {};

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get active price packages returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async getPricePackageForUser(userId) {
        const url = `${AppConfig.appApi}/pricePackageForUser/${userId}`;

        let queryParams = {};

        const resp = await this.executeGet(url, queryParams);
        console.log(`Get active price packages returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async selectPricePackage(userId, packageId) {
        const url = `${AppConfig.appApi}/choosePricePackage`;

        const body = {
            "userId": userId,
            "packageId": packageId
        };

        console.log(`calling ${url} for ${JSON.stringify(body)}`);
        const resp = await this.executePost(url, body);
        console.log(`selectPricePackage returned: ${JSON.stringify(resp)}`);
        return resp.data;
    }
    
    async storeApplicationFile(creatorId, fileName, data) {
        const url = `${AppConfig.appApi}/creatorFile`;

        const body = {
            "creatorId": creatorId,
            "fileName": fileName,
            "data": data
        };

        console.log(`calling ${url} for ${JSON.stringify(body)}`);
        const resp = await this.executePost(url, body);
        return resp.data;
      }
    
      async getApplicationFile(creatorId, fileName,) {
        const url = `${AppConfig.appApi}/creatorFile/${creatorId}/${fileName}`;

        let queryParams = {};
        let resp = null;
        
        resp = await this.executeGet(url, queryParams);
        return resp.data;
      }

    async generateUuid (creatorId, userId) {
        let uuidJson = await this.getApplicationFile(creatorId, AppConfig.appVars.creatorUuidDocument);
        let uuidRecord = {
            uuid: new BigNumber(userId).toHex()
        };

        if (uuidJson) {
            uuidRecord = uuidJson;
            const uuid = new BigNumber(`0x${uuidRecord.uuid}`);
            uuid.add(1);
            uuidRecord.uuid = uuid.toHex();
        } else {
            let val = new BigNumber(uuidRecord.uuid);

            val = val.multiply(new BigNumber('0x1000000000000000'));
            val = val.add(7297);
            uuidRecord.uuid = val.toHex();
        }

        await this.storeApplicationFile(creatorId, AppConfig.appVars.creatorUuidDocument, JSON.stringify(uuidRecord));
        
        return uuidRecord.uuid;
    }

    async getRemoteFile (url) {
        let queryParams = {};

        const resp = await this.executeGet(url, queryParams);
        
        return resp.data;
    }
}