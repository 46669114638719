import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default function Card1({
    actionTarget, 
    actionTitle, 
    imgUrl, 
    title, 
    description
}) {
    //const navigate = useNavigate();
    
    /*
    const handleSubmit = async () => {
        alert(actionTarget);
        navigate(actionTarget);
    }
        <div className="card p-4 rounded-md shadow bg-white">
            <h4 className="mb-4">Learn more about CreatedByMe</h4>
            <p className="text-muted mb-0">We are a huge marketplace dedicated to connecting great artists of all Superex.</p>

            <div className="mt-3">
                <Link to="/aboutus" className="btn btn-link primary text-dark">Read More <i className="uil uil-arrow-right"></i></Link>
            </div>
            <div className="py-4"></div>
            <div className="position-absolute bottom-0 end-0">
                <img src={united} className="avatar avatar-medium opacity-05" alt=""/>
            </div>
        </div>
    */

    return (
        <div className="card nft-items rounded-md shadow bg-white overflow-hidden mb-1 p-4">
            <div className="d-flex justify-content-between">
                <h4 className="mb-4">{title}</h4>
            </div>
            <p className="text-muted mb-0">{description}</p>
            <div className="mt-3">
                <div className="mt-3">
                    <Link to={actionTarget} className="btn btn-link primary text-dark">{actionTitle}  <i className="uil uil-arrow-right"></i></Link>
                </div>
            </div>
            <div className="py-4"></div>
            <div className="position-absolute bottom-0 end-0">
                <img 
                    src={imgUrl} 
                    className="avatar avatar-medium opacity-05" 
                    alt={title} />
            </div>
        </div>
                            
    )
}