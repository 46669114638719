import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import logoDark from '../assets/images/logo_dark.png'
import logoLight from '../assets/images/logo_light.png'
import logoWhite from '../assets/images/logo_light.png'
import client1 from '../assets/images/client/01.jpg'
import Modal from 'react-bootstrap/Modal';
import mataMask from '../assets/images/wallet/metamask.5801d957d27c65deeef0.png'

import { ethers } from "ethers";
import { store } from '../state';
import { ApplicationsService, UserService } from '../services';
import defaultUserImg from '../assets/images/default_user.gif';
import AppConfig from '../App.config';
import { useNavigate } from "react-router-dom";

const appSrv = new ApplicationsService();

export default function Navbar({navlight, gradient}) {
    
    const [show, setShow] = useState(false);
    let [manu, setManu] = useState();
    let [submenu, setSubManu] = useState();
    let [toggle, setToggle] = useState(false);
    let [search, setSearch] = useState(false);
    let [showUser, setShowUser] = useState(false);
    let [pricePackage, setPricePackage] = useState(null);
    let [scrolling, setScrolling] = useState(false);
    
    const [userProfileImg, setUserProfileImg] = useState(defaultUserImg);
    let [userId, setUserId] = useState(store.state.user.id);
    let [oop, setOop] = useState(false);

    const [data, setdata] = useState({
        address: "",
        Balance: null,
    });

    const navigate = useNavigate();
    let current = window.location.pathname
    let searchRef = useRef(null)
    let userRef = useRef(null)

    useEffect(()=>{
        setManu(current);
        setSubManu(current)

        const getPricePackageForUser = async () => {
            if (store.state.user.loggedIn) {
                const pp = await appSrv.getPricePackageForUser(store.state.user.getId());
                store.state.user.setPackageId(pp.id);

                if(pp.title) {
                    setPricePackage(pp);
                }
            }
        };

        const getUserProfileImage = async (userId) => {
            const upi = await appSrv.getApplicationFile(`user_${userId}`, AppConfig.appVars.userProfileImg);

            if (upi) {
                setUserProfileImg(upi);
            }
        }
        
        if (store.state.user.loggedIn) {
            getUserProfileImage(store.state.user.getId());
        }

        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };

        const searchOutClick = (event) =>{
            if(searchRef.current && !searchRef.current.contains(event.target)) {
                setSearch(false)
            }
        }

        const userOutClick = (event) => {
            if(userRef.current && !userRef.current.contains(event.target)){
                setShowUser(false)
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', searchOutClick)
        window.addEventListener('click', userOutClick)
        window.scrollTo(0, 0)
        
        getPricePackageForUser();

        return()=>{
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', searchOutClick)
            window.removeEventListener('click', userOutClick)
        }
    },[current])

    const btnhandler = () => {
        if (window.ethereum) {
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) =>
                    accountChangeHandler(res[0])
                );
        } else {
            setShow(true)
        }
    };

    const getbalance = (address) => {
        window.ethereum
            .request({
                method: "eth_getBalance",
                params: [address, "latest"],
            })
            .then((balance) => {
                setdata({
                    Balance:
                        ethers.utils.formatEther(balance),
                });
            });
    };

    const accountChangeHandler = (account) => {
        setdata({
            address: account,
        });
        getbalance(account);
    };

    const handleLogout = async () => {
        
        const srv = new UserService();
        
        await srv.logout(userId);

        navigate('/home');
    };

    return (
        <header id="topnav" className={`defaultscroll sticky ${scrolling ? 'nav-sticky' : ''} ${gradient ? 'gradient' : ''}`}>
            <div className="container">
                {navlight ? (
                    <Link className="logo" to="/home">
                        <span className="logo-light-mode">
                            <img src={logoDark} height="26" className="l-dark" alt=""/>
                            <img src={logoWhite} height="26" className="l-light" alt=""/>
                        </span>
                        <img src={logoLight} height="26" className="logo-dark-mode" alt=""/>
                    </Link>
                    ) : (
                        <Link className="logo" to="/">
                        <img src={logoDark} height="26" className="logo-light-mode" alt=""/>
                        <img src={logoLight} height="26" className="logo-dark-mode" alt=""/>
                    </Link>
                )}
                <Link className="logo" to="/alpha">
                    <span className="alpha-button">
                        ALPHA
                    </span>
                </Link>
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link className={`navbar-toggle ${toggle ? 'open' : ''}`} id="isToggle" onClick={(e)=>{setToggle(!toggle)}} >
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0 me-1" ref={searchRef}>
                        <div className="dropdown">
                            {navlight ? (
                                <button type="button" className="btn dropdown-toggle p-0" onClick={(e)=>{setSearch(!search)}} >
                                    <i className="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                                    <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                                </button>
                                ) : (
                                <button type="button" className="btn dropdown-toggle p-0"onClick={(e)=>{setSearch(!search)}} >
                                    <i className="uil uil-search text-dark fs-5 align-middle"></i>
                                </button>
                            )}
                            
                            
                            {search && (
                                <div className="dropdown-menu dd-menu d-block dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0 end-0" style={{width:'300px'}}>
                                    <div className="search-bar">
                                        <div id="itemSearch" className="menu-search mb-0">
                                            <form className="searchform">
                                                <input type="text" className="form-control border rounded shadow" name="s" id="s" placeholder="Search..."/>
                                                <input type="submit" id="searchItemsubmit" value="Search"/>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!store.state.user.loggedIn && (
                                <Link to="/login" className="parent-parent-menu-item top-level-menu mx-3">Login</Link>
                            )}
                        </div>
                    </li>

                    
                    <li className="list-inline-item mb-0" ref={userRef}>
                        <div className="dropdown dropdown-primary">
                            {store.state.user?.loggedIn && (
                                <button 
                                    type="button" 
                                    className="btn btn-pills dropdown-toggle p-0" 
                                    onClick={() => setShowUser(!oop)}>
                                    <img src={userProfileImg} className="rounded-pill avatar avatar-sm-sm" alt=""/>
                                </button>
                            )}
                            {showUser && store.state.user.loggedIn && (
                                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded d-block  end-0" style={{width:'200px'}}>
                                    <div className="position-relative">
                                        <div className="pt-5 pb-3 bg-gradient-primary"></div>
                                        <div className="px-3">
                                            <div className="d-flex align-items-end mt-n4">
                                                <img src={userProfileImg} className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md" alt=""/>
                                                <h6 className="text-dark fw-bold mb-0 ms-1">{store.state.user?.getEmailAddress()}</h6>
                                            </div>
                                            {pricePackage &&(
                                            <div className="mt-2">
                                                <small className="text-start text-dark d-block fw-bold">Package:</small>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <small id="myPublicAddress" className="text-muted">{pricePackage.title}</small>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        {(store.state.user?.getCreatorId() !== null && store.state.user.getCreatorId() !== '') && (
                                            <>
                                            <Link 
                                                className="dropdown-item small fw-semibold text-dark d-flex align-items-center" 
                                                to={"/creator-profile/" + store.state.user?.getCreatorId()}>
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                                                </span> 
                                                Profile
                                            </Link>
                                            <Link 
                                            className="dropdown-item small fw-semibold text-dark d-flex align-items-center" 
                                            to={"/creator-series"}>
                                            <span className="mb-0 d-inline-block me-1">
                                                <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                                            </span> 
                                            Manage Series
                                        </Link>
                                        <Link 
                                            className="dropdown-item small fw-semibold text-dark d-flex align-items-center" 
                                            to={"/register-work"}>
                                            <span className="mb-0 d-inline-block me-1">
                                                <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                                            </span> 
                                            Manage Works
                                        </Link>
                                        </>
                                        )}
                                        {(store.state.user?.getCreatorId() == null || store.state.user.getCreatorId() === '') && (
                                            <Link 
                                                className="dropdown-item small fw-semibold text-dark d-flex align-items-center" 
                                                to="/become-creator">
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="uil uil-palette align-middle h6 mb-0 me-1"></i>
                                                </span> 
                                                Become A Creator
                                            </Link>
                                        )}
                                        <div className="dropdown-divider border-top"></div>
                                        <Link 
                                            className="dropdown-item small fw-semibold text-dark d-flex align-items-center" 
                                            to="/lock-screen"
                                            onClick={handleLogout}>
                                                <span className="mb-0 d-inline-block me-1"><i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i></span>
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                </ul>
        
                <div id="navigation" style={{display : toggle ? 'block' : 'none'}}>
                    <ul className={`navigation-menu nav-left ${navlight ? 'nav-light' : ''}`}>
                        {manu !== '/' &&
                        <li className={`has-submenu parent-parent-menu-item ${manu === '/' ? 'active' : ''}`}>
                            <Link to="/" >Home</Link>
                        </li>
                        }
                        
                        <li className={`ms-0 ${manu === '/creators' ? 'active' : ''}`}><Link to="/creators" className="sub-menu-item"> Creators</Link></li>
                        
                        <li className={`has-submenu parent-parent-menu-item ${['/works-item', '/explore', '/auction'].includes(manu) ? 'active' : ''}`}>
                            <Link to="#" onClick={()=>setSubManu(submenu === '/works-item' ? '' : '/works-item')}>Works</Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${['/works-item', '/explore', '/auction'].includes(submenu) ? 'open' : ''}`}>
                                <li className={`ms-0 ${manu === '/explore' ? 'active' : ''}`}><Link to="/explore" className="sub-menu-item"> Explore Works</Link></li>
                                <li className={`ms-0 ${manu === '/auction' ? 'active' : ''}`}><Link to="/auction" className="sub-menu-item">Auctions</Link></li>
                                <li className={`ms-0 ${manu === '/for-sale' ? 'active' : ''}`}><Link to="/for-sale" className="sub-menu-item">For Sale</Link></li>

                            </ul>
                        </li>
                             
                        <li className={`has-submenu parent-parent-menu-item ${['/about', '/about-us', '/contact'].includes(manu) ? 'active' : ''}`}>
                            <Link to="#" onClick={()=>setSubManu(submenu === '/aboutus' ? '' : '/contact')}>About</Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${['/aboutus', '/contact'].includes(submenu) ? 'open' : ''}`}>
                                <li className={`ms-0 ${manu === '/aboutus' ? 'active' : ''}`}><Link to="/aboutus" className="sub-menu-item"> Who We Are</Link></li>
                                <li className={`ms-0 ${manu === '/contact' ? 'active' : ''}`}><Link to="/contact" className="sub-menu-item"> Contact Us</Link></li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
         <Modal show={show} onHide={()=>setShow(false)}>
         <Modal.Body className='text-center'>
            <img src={mataMask} className="avatar avatar-md-md rounded-circle shadow-sm " alt=""/>

            <div className="content mt-4">
                <h5 className="text-danger mb-4">Error!</h5>

                <p className="text-muted">Please Download MetaMask and create your profile and wallet in MetaMask. Please click and check the details,</p>

                <Link to="https://metamask.io/" className="btn btn-link primary text-primary fw-bold" target="_blank">MetaMask</Link>
            </div>
         </Modal.Body>
       </Modal>
        </header>
  )
}
