import React from 'react';

export default function WorkImage({
    title,
    url,
    alt,
    options
}) {
    const w = options?.width != null ? options.width : 64;
    return (
        <>
        <h6>{title}</h6>
        <img src={url} alt={alt} width={w}/>
        </>
    );
}