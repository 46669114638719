import axios from "axios";
import { store } from '../state';

export class BaseService {
    executeGet (baseUrl, queryParams) {
        const result = {
            status: 400,
            data: null
        };

        const options = {params: queryParams};
        const auth = this.authHeader(null);

        if (auth) {
            options['headers'] = auth;
        }

        return axios.get(baseUrl, options)
        .then(function (response) {
            result.status = response.status;
            result.data = response.data;
            return result;
        })
        .catch(function (error) {
            // handle error
            console.log(error);

            result.status = error.response.status;
            result.data = null;
            return result;
        })
    }

    async executePost (baseUrl, body, multiPartFormData) {
        const result = {
            status: 400,
            data: null,
            error: null
        };
        const contentType =  multiPartFormData ? 'application/x-www-form-urlencoded' : 'application/json';
        const data = multiPartFormData ? body : JSON.stringify(body);

        const options = {};
        options['headers'] = this.authHeader({
            'Content-Type': contentType
        });

        const resp = await axios.post(baseUrl, data, options)
        .then(function (response) {
            try {
                if (response.status !== 201) {
                    console.log(`POST Request failed with: ${response.statusText}`);
                }
                result.status = response.status;
                result.data = response.data;
            } catch (exp) {
                result.status = 500;
                result.error = exp;
            }
            return result;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            result.status = error.response.status;
            result.data = null;
            result.error = error;
            return result;
        });

        return resp;
    }

    async executeDelete (baseUrl, queryParams) {
        const result = {
            status: 400,
            data: null,
            error: null
        };
        const options = {params: queryParams};
        const auth = this.authHeader(null);

        if (auth) {
            options['headers'] = auth;
        }

        const resp = await axios.delete(baseUrl, options)
        .then(function (response) {
            if (response.status < 200 || response.status > 299) {
                console.log(`DELETE Request failed with: ${response.statusText}`);
            }
            result.status = response.status;
            result.data = response.data;
            return result;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            result.status = error.response.status;
            result.data = null;
            result.error = error;
            return result;
        });

        return resp;
    }

    authHeader (headers) {
        let resp = headers;

        if (store.state.user.loggedIn) {
            const encoded = btoa(`${store.state.user.getId()}|${store.state.user.getSessionId()}`).toString('base64');
            if (resp === null) {
                resp = {};
            }
            resp['Authorization'] = `Basic:${encoded}`;
        }

        return resp;
    }
}