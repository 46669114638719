import React from 'react'

export default function PricePackage ({
    id,title,price,timeUnit,priceProperties, active,callback
}) {
    
    const handleSelect = async () => {
        callback(id);
    }

    return(
        <div className={`pricing-card mx-4 box-shadow h-100 text-center ${active ? 'pricing-card-selected' : ''}`}>
          <div className="pricing-card-header">
            <h4 className="my-0 font-weight-normal">{title}</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">${price} <small className="text-muted">/ {timeUnit}</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
            {priceProperties.split('|').map((p, index) => {
            return (
                <li key={id+'-'+index}>{p}</li>
                );
            })}
            </ul>
  
            <button 
                className="btn btn-lg btn-block btn-outline-primary" 
                type="button" 
                onClick={handleSelect}>
                Select
            </button>
          </div>
        </div>
    );
}