import React from 'react'

export default function BlogComment({
    blogId,
    commentId,
    author,
    commentDate,
    comment,
}) {
  return (
    <div key={`${blogId}_${commentId}`} className="blog-comment col-lg-8 overflow-hidden mt-3">
        <div className="author">{author}</div>
        <div className="date">{commentDate}</div>
        <p>{comment}</p>
    </div>
  );
}