import { BaseService } from './base.service';
import AppConfig from '../App.config';
import { store } from '../state';

export class UserService extends BaseService {
    async login (userEmail, password) {
        const url = `${AppConfig.userApi}/login`;
        const body = {
        'userEmailAddress': userEmail,
        'password': password
        };

        let resp = {
            status: 400,
            data: -1,
            error: null
        };

        try {
            resp = await this.executePost (url, body, false);
        } catch(error) {
            resp.error = error;
        }

        console.log(`Login returned: ${JSON.stringify(resp)}`);

        if(resp.status === 201 && resp.data !== '') {
            const user = await this.getUser(resp.data);
            store.state.user.login(user);
        } else {
            resp = {
                status: 401,
                data: -1,
                error: 'login failed'
            };
        }

        return resp;
    }    
    
    async logout (userId) {
        let result = false;
        const url = `${AppConfig.userApi}/logout`;
        const body = { 'userId': userId };

        const resp = await this.executePost (url, body, false);

        console.log(`Logout returned: ${JSON.stringify(resp)}`)

        store.state.user.logout();

        result = resp.status === 201;
        
        return result;
    }

    async getUser(userId) {
        const url = `${AppConfig.userApi}/${userId}`;

        const resp = await this.executeGet(url, {});

        console.log(`Get user returned: ${JSON.stringify(resp)}`)

        return resp.data;
    }

    async changePassword (userId, oldPassword, newPassword) {
        const url = `${AppConfig.userApi}/changePassword`;
        const body = {
            'userId': userId,
            'oldPassword': oldPassword,
            'newPassword': newPassword
        };

        let result = false;

        try {
            const resp = await this.executePost (url, body, false);

            result = resp.status === 202 
        } catch(error) {
            console.log(error);
        }

        console.log(`Login returned: ${JSON.stringify(result)}`)

        return result;
    }

    async createAccount (userEmail, password) {
        const url = `${AppConfig.userApi}`;

        const body = {
            'userEmailAddress': userEmail,
            'password': password
        };

        let result = -1;

        try {
            const resp = await this.executePost (url, body, false);

            if (resp.status === 201) {
                result = resp.data;
            }
        } catch(error) {
            console.log(error);
        }

        console.log(`Login returned: ${JSON.stringify(result)}`)

        return result;
    }
}