import React,{useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { BlogService, CreatorService, IpfsService } from '../../services';
import AppConfig from '../../App.config';
import Dropzone from 'react-dropzone';
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  tablePlugin,
  linkPlugin,
  linkDialogPlugin,
  UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin,
  BlockTypeSelect,
  InsertTable,
  ListsToggle,
  MDXEditor,
  CreateLink,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import 'react-toastify/dist/ReactToastify.css';

const blogSrv = new BlogService();
const creatorSrv = new CreatorService();
const ipfsSrv = new IpfsService();

export default function BlogDetail() {
    const [blogTitle, setBlogTitle] = useState('');
    const [blogTopic, setBlogTopic] = useState('');
    const [blogHashTags, setBlogHashTags] = useState('');
    const [blogSummary, setBlogSummary] = useState('');
    const [blogData, setBlogData] = useState('');
    const [blogImage, setBlogImage] = useState(null);
    const [creatorData, setCreatorData] = useState(null);
    const [blogValid, setBlogValid] = useState(false);
    let [proposedQid, setProposedQid] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        const getCreatorRecord = async (id) => {
            await creatorSrv.getCreator(id).then(rec => {
                setCreatorData(rec);
            });
        };

        if (store.state.user.loggedIn) {
            if (store.state.user.getCreatorId()) {
                getCreatorRecord(store.state.user.getCreatorId());
            } else {
                toast.warn('Not a creator');
                navigate('/');
            }
        } else {
            toast.warn('Not logged in.');
            navigate('/');
        }
    }, []);

    const determineBlogValid = () => {
        const result = blogTitle !== null && blogTitle.trim() !== '' &&
            blogSummary !== null && blogSummary.trim() !== '' &&
            blogData !== null && blogData.trim() !== '';

        setBlogValid(result);
    };

    const cancelCreateBlog = () => {
        navigate(`/creator-profile/${creatorData.displayName}`);
    };

    const handlePostBlog = async () => {
        if (blogValid) {
            const resp = await blogSrv.addBlogEntry(
                creatorData.creatorId,
                blogTitle,
                blogTopic,
                blogHashTags,
                blogSummary,
                blogImage,
                blogData,
                0
            );
            
            if (resp.status == 201) {
                toast.success("Blog entry posted!");
                navigate(`/creator-profile/${creatorData.displayName}`)
            } else {
                toast.error(`Failed to post blog entry: ${resp.error}`);
            }
        } else {
            toast.warn ('Please enter Title, Summary, and body for blog entry.');
        }
    }

    const handleChangeImage = async () => {
        if (proposedQid) {
            await ipfsSrv.removeQid(proposedQid);

            toast.info("Image removed.");
        }

        setProposedQid(null);
        setBlogImage(null);
    };

    const onDrop = async (acceptedFiles) => {
        console.log(acceptedFiles);

        if (acceptedFiles && acceptedFiles.length > 0) {
            // If a current file laded - remove it.
            if (proposedQid) {
                await ipfsSrv.removeQid(proposedQid);
            }
            const resp = await ipfsSrv.upload(acceptedFiles[0]);

            if(resp) {
                toast.success('Image saved successfully.');

                setProposedQid(resp);
                setBlogImage(`${AppConfig.ipfsRetrievePath}/${resp}`);
            }
        }
    }

    return (
    <>
        <Navbar navlight={true}/>
     
        <section className="section mt-5">
            <div className="container ">
                <div className="row rounded-md p-2">
                    <div className="col-lg-8">
                        <label className="form-label">
                            Blog Title 
                            <i className="mdi mdi-help-circle-outline blog-title-tooltip mx-2" />
                            <Tooltip anchorSelect=".blog-title-tooltip" place="top">
                                The title of the blog entry
                            </Tooltip>
                        </label>
                        <input 
                            name="blogTitle" 
                            id="blogTitle" 
                            type="text" 
                            className="form-control" 
                            placeholder="title" 
                            value={blogTitle}
                            onChange={(e) => {
                                setBlogTitle(e.target.value);
                                determineBlogValid();}}/>
                    </div>
                </div>
                <div className="row rounded-md p-2">
                    <div className="col-lg-8">
                        <label className="form-label">
                            Topic 
                            <i className="mdi mdi-help-circle-outline blog-topic-tooltip mx-2" />
                            <Tooltip anchorSelect=".blog-topic-tooltip" place="top">
                                Optional grouping topic
                            </Tooltip>
                        </label>
                        <input 
                            name="blogTopic" 
                            id="blogTopic" 
                            type="text" 
                            className="form-control" 
                            placeholder="topic" 
                            value={blogTopic}
                            onChange={(e) => setBlogTopic(e.target.value)}/>
                    </div>
                </div>
                <div className="row rounded-md p-2">
                    <div className="col-lg-8">
                        <label className="form-label">
                            Summary 
                            <i className="mdi mdi-help-circle-outline blog-summary-tooltip mx-2" />
                            <Tooltip anchorSelect=".blog-summary-tooltip" place="top">
                                A summary of the blog entry
                            </Tooltip>
                        </label>
                        <input 
                            name="blogSummary" 
                            id="blogSummary" 
                            type="text" 
                            className="form-control" 
                            placeholder="summary" 
                            value={blogSummary}
                            onChange={(e) => {
                                setBlogSummary(e.target.value);
                                determineBlogValid();}}/>
                    </div>
                </div>
                
                <div className="col-lg-12 mt-3 mb-3">
                    <label className="form-label">
                        Title Image 
                        <i className="mdi mdi-help-circle-outline blog-image-tooltip mx-2" />
                        <Tooltip anchorSelect=".blog-image-tooltip" place="top">
                            Optional main blog entry image. 
                        </Tooltip>
                    </label>
                    <div className="row" id="blogImage" name="blogImage">
                        {(blogImage !== null && blogImage !== '') && (
                            <>
                            <div className="col-10">
                                <img 
                                    alt={blogTitle}
                                    src={blogImage}
                                    width="256" />
                            </div>
                            <div className="col-10">
                                <button 
                                    type="button" 
                                    id="changeImgBtn" 
                                    name="changeImgBtn" 
                                    onClick={() => handleChangeImage()}
                                    className={"btn btn-primary rounded-md mt-3" }>
                                    Change / Remove Image
                                </button>
                            </div>
                            </>
                        )}
                        {(blogImage === null || blogImage === '') && (
                        <Dropzone onDrop={onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className="load-img-dz mx-3">
                            <input {...getInputProps()}/>
                                
                            </div>
                        )}
                        </Dropzone>
                        )}
                    </div>
                
                </div>

               
                <div className="row rounded-md p-2">
                    <div className="col-lg-8">
                        <label className="form-label">
                            Entry Body 
                        </label>
                    </div>
                </div>
                <div className="row bg-dark rounded-md p-2">
                    <div className="col-lg-8">
                        <MDXEditor  
                            markdown={blogData} 
                            onChange={(e) => {
                                setBlogData(e);
                                determineBlogValid();
                                }}
                            plugins={[headingsPlugin(),
                            quotePlugin(),
                            listsPlugin(),
                            tablePlugin(),
                            linkPlugin(),
                            linkDialogPlugin(),
                            toolbarPlugin({
                                toolbarClassName: 'my-classname',
                                toolbarContents: () => (
                                <>
                                    {' '}
                                    <UndoRedo />
                                    <BlockTypeSelect />
                                    <BoldItalicUnderlineToggles />
                                    <CreateLink />
                                    <ListsToggle />
                                    <InsertTable />
                                </>
                                )
                            })
                        ]} />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-10 col-md-2 col-lg-2 col-xl-2">
                        <button 
                        type="button" 
                        id="submit" 
                        name="send" 
                        onClick={handlePostBlog}
                        disabled={!blogValid}
                        className="btn btn-primary rounded-md">Post</button>
                    </div>
                    <div className="col-10 col-md-3 col-lg-2 mt-3 mt-md-0">
                        <button 
                        type="button" 
                        id="cancelEdit" 
                        name="cancelEdit" 
                        onClick={cancelCreateBlog}
                        className="btn btn-primary rounded-md">Cancel</button>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ToastContainer
            position="bottom-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    )
}  