import { AppStorage } from "./storage";

class SessionState extends AppStorage {
    constructor () {
        super("session-state");
        this.load();

        if (!this.data.redirectTo) {
            this.data = {
                redirectTo: ''
            };
        }
    }

    setRedirectTo(url) {
        this.data.redirectTo = url;
        this.store();
    }

    getRedirectTo() {
        if (!this.data.redirectTo) {
            this.load();
        }

        return this.data.redirectTo;
    }
}

const appSessionState = new SessionState();
export default appSessionState; 