import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

export default function WorkVideo({
    title,
    url,
    alt,
    options
}) {
    
    const [isOpen, setOpen] = useState(false);

    if (options?.channel === 'youtube') {
        return (
            <div className="position-relative">
                <div className="play-icon">
                    <Link to="#" onClick={() => setOpen(true)} className="play-btn lightbox">
                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg icons"></i>
                        {title}
                    </Link>
                </div>
                <ModalVideo
                    channel="youtube"
                    youtube={{ mute: 0, autoplay: 0 }}
                    isOpen={isOpen}
                    videoId={options?.videoId}
                    onClose={() => setOpen(false)} 
                />
            </div>
        );
    } else if (options?.channel === 'vimeo') {
        return (
            <div className="position-relative">
                <div className="play-icon">
                    <Link to="#" onClick={() => setOpen(true)} className="play-btn lightbox">
                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg icons"></i>
                        {title}
                    </Link>
                </div>
                <ModalVideo
                    channel="vimeo"
                    vimeo={{ mute: 0, autoplay: 0 }}
                    isOpen={isOpen}
                    videoId={options?.videoId}
                    onClose={() => setOpen(false)} 
                />
            </div>
        );
    } else {
        return (
            <div className="position-relative">
                <div className="play-icon">
                    <Link to="#" onClick={() => setOpen(true)} className="play-btn lightbox">
                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg icons"></i>
                        {title}
                    </Link>
                </div>
                <ModalVideo
                    channel="custom"
                    vimeo={{ url: url }}
                    isOpen={isOpen}
                    onClose={() => setOpen(false)} 
                />
            </div>
        );
    }
}