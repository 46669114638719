import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import bg1 from '../../assets/images/bg/gallery_1.png';
import defaultAssetImg from '../../assets/images/svg/office-desk.svg';
import Navbar from '../../components/navbar';
import Subscription from '../../components/subscription';
import Footer from '../../components/footer';
import {ApplicationsService, AssetService} from '../../services';
import AppConfig from '../../App.config';
import Select from 'react-select';
import defaultUserImg from '../../assets/images/default_user.gif';

const appSrv = new ApplicationsService();
const assetSrv = new AssetService();

export default function ExploreWorks() {
  const [ assetData, setAssetData ] = useState(null);
  const [ assetMetadata, setAssetMetadata ] = useState(null);
  const [ cursor, setCursor ] = useState(null);
  const [ creatorImages, setCreatorImages ] = useState({});
    
  useEffect(()=>{
      const getAssetData = async () => {
        const data = await assetSrv.getAssetList(25, null, null, cursor);
        setAssetData(data);

        if (data && data.assets) {
            const rawCreatorIds = data.assets.map(a => {
                return a.creatorId;
            });

            const creatorIds = [];

            for(let i = 0; i < rawCreatorIds.length; i++) {
                if (creatorIds.indexOf(rawCreatorIds[i]) < 0) {
                    creatorIds.push(rawCreatorIds[i]);
                }
            }

            const metadataPromises = data.assets.map (ad => {
                ad.url = ad.url.replace('http://192.168.4.148', 'https://createdbyme.io');
                
                return appSrv.getRemoteFile(ad.url)
                    .then(md => {
                        return {aid: ad.assetId, data: md};
                    });
            });

            const creatorPromises = creatorIds.map(cid => {
                return appSrv.getApplicationFile(cid, AppConfig.appVars.creatorProfileImg)
                    .then(resp => {
                        return {cid: cid, data: resp};
                    });
            });

            const promises = [
                Promise.all(creatorPromises).then(resp => {
                    let ci = {};
                    
                    for (let i = 0; i < resp.length; i++) {
                        Object.defineProperty(ci, `_${resp[i].cid}`, {value:resp[i].data});
                    }

                    setCreatorImages(ci);
                }),
                Promise.all(metadataPromises).then(resp => {
                    let md = {};
                    
                    for (let i = 0; i < resp.length; i++) {
                        resp[i].data.image = resp[i].data.image.replace('http://192.168.4.148', 'https://createdbyme.io');
                        Object.defineProperty(md, `_${resp[i].aid}`, {value:resp[i].data});
                    }
                    
                    setAssetMetadata(md);
                })
            ];

            await Promise.all(promises);
        }
      }

      getAssetData();
  }, [])

  const product = [
    { value: '3', label: 'Auction Product' },
    { value: '1', label: 'On Sale' },
    { value: '3', label: 'Offers' },
  ]

  return (
    <>
    <Navbar navlight={true}/>
    <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url("${bg1}")` , backgroundPosition:'bottom'}}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">Explore Works</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">Explore Creator's Work</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">            
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <section className="section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="features-absolute">
                        <div className="row justify-content-center" id="reserve-form">
                            <div className="col-xl-10 mt-lg-5">
                                <div className="card bg-white feature-top border-0 shadow rounded p-3">
                                    <form action="#">
                                        <div className="registration-form text-dark text-start">
                                            <div className="row g-lg-0">
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="filter-search-form position-relative filter-border">
                                                        <i className="uil uil-search icons"></i>
                                                        <input name="name" type="text" id="search-keyword" className="form-control filter-input-box bg-light border-0" placeholder="Search your keaywords"/>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 mt-3 mt-md-0">
                                                    <div className="filter-search-form bg-light position-relative filter-border">
                                                        <i className="uil uil-usd-circle icons"></i>
                                                        <Select options={product} />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                                                    <div className="filter-search-form bg-light position-relative filter-border">
                                                        <i className="uil uil-window icons"></i>
                                                        <Select options={product} />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                                                    <input type="submit" id="search" name="search" style={{height:'60px'}} className="btn btn-primary rounded-md searchbtn submit-btn w-100" value="Search"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              {assetData?.assets.map((item,index)=>{
                return(
                    <div className="col mt-4 pt-2" key={index}>
                        <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex justify-content-between">
                            <div className="img-group">
                                <Link to="/creator-profile" className="user-avatar">
                                    {(creatorImages && creatorImages[`_${item.creatorId}`] !== null ) && (
                                    <img src={creatorImages[`_${item.creatorId}`]} alt="creator" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                    )}
                                    {(creatorImages && creatorImages[`_${item.creatorId}`] === null)  && (
                                    <img src={defaultUserImg} alt="" className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                    )}
                                </Link>
                            </div>
                            
                            <span className="like-icon shadow-sm"><Link to="#" className="text-muted icon"><i className="mdi mdi-18px mdi-heart mb-0"></i></Link></span>
                        </div>

                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                            {(assetMetadata && assetMetadata[`_${item.assetId}`] !== null) && (
                            <Link to={`/work/${item.assetId}`}><img src={assetMetadata[`_${item.assetId}`].image} className="img-fluid" alt=""/></Link>
                            )}
                            {(assetMetadata && assetMetadata[`_${item.assetId}`] === null) && (
                            <Link to={`/work/${item.assetId}`}><img src={defaultAssetImg} className="img-fluid" alt=""/></Link>
                            )}
                            <div className="position-absolute top-0 start-0 m-2">
                                <Link to="#" className="badge badge-link bg-primary">{item.tag}</Link>
                            </div>

                            <div className="position-absolute top-0 end-0 m-2">
                                <Link to={`/work/${item.assetId}`} className="btn btn-pills btn-icon"><i className="uil uil-shopping-cart-alt"></i></Link>
                            </div>
                        </div>

                        <div className="card-body content position-relative p-0 mt-3">
                            <Link to={`/work/${item.assetId}`} className="title text-dark h6">{item.name}</Link>

                            <div className="d-flex justify-content-between mt-2">
                                
                                <small className="rate fw-bold">
                                {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.value) && (
                                    <>
                                    {assetMetadata[`_${item.assetId}`].properties?.value} {assetMetadata[`_${item.assetId}`].properties?.currency}
                                    </>
                                )}
                                </small>
                                
                                <small className="text-dark fw-bold">
                                {(assetMetadata && assetMetadata[`_${item.assetId}`]?.properties?.copyId) && (
                                    <>
                                    {assetMetadata[`_${item.assetId}`].properties.copyId} out of {assetMetadata[`_${item.assetId}`].properties.numberOfCopies}
                                    </>
                                )}
                                </small>
                                
                                
                            </div>
                        </div>
                        </div>
                    </div>
                )
              })}
            </div>

            <div className="row justify-content-center mt-4">
                <div className="col">
                    <div className="text-center">
                        <Link to="#" className="btn btn-primary rounded-md"><i className="uil uil-process mdi-spin me-1"></i> Load More</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}
