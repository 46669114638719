import React from 'react'
import { Link } from 'react-router-dom'

import bg1 from '../assets/images/bg/wood_both.png'
import about from '../assets/images/bg/artist_2.png'

import Navbar from '../components/navbar'
import Blog from '../components/blog'

import CountUp from 'react-countup';
import { teamData } from '../data/data';
import Footer from '../components/footer';

export default function Aboutus() {
  return (
    <>
    <Navbar navlight={true}/>
    <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url("${bg1}")` , backgroundPosition:'bottom'}}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">Created by Me</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">
                            A space where artists can establish their brand and tell their story
                        </p>
                    </div>
                </div>
            </div>

            <div className="position-middle-bottom">
                <nav aria-label="breadcrumb" className="d-block">
                    <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">ABOUT US</li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
    <div className="position-relative">            
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section className="section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                    <div className="about-image position-relative">
                        <img src={about} className="img-fluid rounded shadow" alt=""/>
                    </div>
                </div>


                <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="section-title ms-lg-5">
                        <h6 className="text-primary fw-normal">Creative Vision & Mission</h6>
                        <h4 className="title mb-4">We enable creators <br /> to share their stories <br/> and bring their art <br /> to the world.</h4>
                        <p className="text-white-100 para-desc mb-3">Artists and Creaftsmen hone their craft through countless hours of practice. Time that could have been spent with family, friends, in relaxation or other pastimes.</p>
                        <p className="text-white-100 para-desc mb-3">It is rarely easy. It requires patience, perseverance, and a belief that obstacles and failures are chances to learn and grow.</p>
                        <p className="text-white-100 para-desc mb-3">They are mortal, yet trade their limited time learning, creating, building, inventing. Testing limits. </p>
                        <p className="text-white-100 para-desc mb-3">In a world of AI and Automation, unique, varified human-made art becomes more valuable. Created By Me enables the artist or craftsman to share their story and establish provenance, proving when and by whom their art has been created.</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}
