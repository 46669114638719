import React, { useEffect, useState } from "react";
import Navbar from '../../components/navbar'
import Footer from "../../components/footer";
import cbg from '../../assets/images/work/1.jpg';
import {ApplicationsService, CreatorService} from '../../services';
import { useNavigate } from "react-router-dom";
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import AppConfig from '../../App.config';
import { ToastContainer, toast } from 'react-toastify';

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();

export default function CreatorSeries() {
    let [creatorData, setCreatorData] = useState(null);
    let [creatorBgImg, setCreatorBgImg] = useState(cbg);
    let [creatorId, setCreatorId] = useState(null);
    let [seriesCursor, setSeriesCursor] = useState(null);
    let [seriesData, setSeriesData] = useState([]);
    let [addingSeries, setAddingSeries] = useState(false);
    let [proposedSeriesId, setProposedSeriesId] = useState('');
    let [proposedDescription, setProposedDescription] = useState('');
    
    const navigate = useNavigate();

    useEffect(()=>{
        const cid = store.state.user.getCreatorId();
        const isCreator = store.state.user.loggedIn &&
            (cid && cid !== '');

        const getCreatorRecord = async (cid) => {
            const promises = [];
            promises.push( creatorSrv.getCreatorByName(cid).then(rec => {
                    setCreatorData(rec);
                    getCreatorBgImage(rec.creatorId);
                })
            );

            promises.push(retrieveSeriesList(cid));

            await Promise.all(promises);
        };   
          
        const retrieveSeriesList = async (cid) => {
            const sl = await creatorSrv.getSeriesList(cid, 100, null, null, seriesCursor)
                .then((sl) => {
                    setSeriesCursor(sl.cursor);
                    return sl;
                });
            
            if (sl && sl.series.length > 0) {
                setSeriesData(sl.series);
            }
        }

        const getCreatorBgImage = async (id = null) => {
            let upi = null;
    
            if (id) {
                upi = await appSrv.getApplicationFile(id, AppConfig.appVars.creatorBackground);
            }
    
            if (upi) {
                setCreatorBgImg(upi);
            }
        }

        if (isCreator) {
            getCreatorBgImage(cid);

            if (cid) {
                setCreatorId(cid);
                getCreatorRecord(cid);
            }
        } else {
            navigate(`/`);
        }
    }, [navigate]);



    const handleAddSeries = async () => {
        if (proposedSeriesId === '') {
            toast.error('Series Id is required.');
            return;
        }

        if (proposedDescription === '') {
            toast.error('Description is required.');
            return;
        }

        await creatorSrv.createSeries(creatorId, proposedSeriesId, proposedDescription);

        const seriesItems = seriesData;
        seriesItems.push({
            creatorId: creatorId,
            seriesId: proposedSeriesId,
            description: proposedDescription
        });

        toast.success(`Added series "${proposedSeriesId}"`);
        
        setSeriesData(seriesItems);
        setProposedDescription('');
        setProposedSeriesId('');

    }

    const handleCancelAddSeries = () => {
        setAddingSeries(false);
        setProposedDescription('');
        setProposedSeriesId('');
    }
    return (
    <>
    <Navbar/>
      <section className="bg-creator-profile">
        <div className="container">
            <div className="profile-banner">
                <div className="position-relative d-inline-block">
                    <img src={creatorBgImg} className="rounded-md shadow-sm img-fluid" id="profile-banner" alt=""/>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center mt-n100">

                        <div className="content mt-3">
                            <h5 className="mb-3">
                                Series List 
                                <i className="mdi mdi-vector-link section-title-tooltip mx-2" />
                                <Tooltip anchorSelect=".section-title-tooltip" place="top">
                                    This value is stored on chain.
                                </Tooltip>
                            </h5>
                            <small className="text-muted d-block">Describes sets / groups of related art / works.</small>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-lg-12 mt-5">      
                {seriesData.length > 0 && (
                <div className="col-lg-12 mt-3">
                    <table> 
                        <thead>
                            <tr>
                                <td><h5>Series Id</h5></td>
                                <td className="ps-3"><h5>Description</h5></td>
                            </tr>
                        </thead>
                        <tbody>
                            {seriesData.map((sd, idx) => {
                            return (
                            <tr
                                id={`sd_${idx}`}
                                name={`sd_${idx}`}
                                key={`sd_${idx}`}>
                                    <td >{sd.seriesId}</td>
                                    <td className="ps-3">{sd.description}</td>
                            </tr>
                            );
                            })}
                        </tbody>
                    </table>
                </div>
                )}
                
                {addingSeries && (
                <div className="col-lg-12 mt-3">
                    <p className="small"></p>
                    <div className="d-flex flex-column">
                        <div className="flex-fill">
                            <label className="form-label">
                                Series Id
                                <i className="mdi mdi-help-circle-outline series-id-tooltip mx-2" />
                                <Tooltip anchorSelect=".series-id-tooltip" place="top">
                                    Unique Series Title - limit is 32 characters
                                </Tooltip>
                            </label>
                            <input 
                                className="form-control"
                                onChange={(e) => setProposedSeriesId(e.target.value)}
                                placeholder="Series Title"
                                value={proposedSeriesId} />
                        </div>
                        <span className={"warning-text " + (proposedSeriesId === '' ? "" : "hidden")}>required</span>
                        <span className={"warning-text " + (proposedSeriesId.length > 32 ? "" : "hidden")}>limit 32 characters</span>
                    </div>

                    <div className="d-flex flex-column">
                        <div className="flex-fill">
                            <label className="form-label">
                                Description
                                <i className="mdi mdi-help-circle-outline series-desc-tooltip mx-2" />
                                <Tooltip anchorSelect=".series-desc-tooltip" place="top">
                                    Short description of the series.
                                </Tooltip>
                            </label>
                            <input 
                                className="form-control"
                                onChange={(e) => setProposedDescription(e.target.value)}
                                placeholder="Series Description"
                                value={proposedDescription} />
                        </div>
                        <span className={"warning-text " + (proposedDescription === '' ? "" : "hidden")}>required</span>
                    </div>
                    <button 
                        type="button" 
                        id="addImgBtn" 
                        name="addImgBtn" 
                        onClick={() => handleAddSeries()}
                        className={"btn btn-primary rounded-md mt-3" }>
                        Add
                    </button>
                    <button 
                        type="button" 
                        id="cancelAddImgBtn" 
                        name="cancelAddImgBtn" 
                        onClick={handleCancelAddSeries}
                        className={"btn btn-primary rounded-md ms-3 mt-3" }>
                        Cancel
                    </button>
                
                </div>
                )}
                
                {!addingSeries && (
                <button 
                    type="button" 
                    id="toggleAddImgBtn" 
                    name="toggleAddImgBtn" 
                    onClick={() => setAddingSeries(true)}
                    className={"btn btn-primary rounded-md mt-3" }>
                    Add Series
                </button>
                )}  
            </div>
        </div>
    </section>
    <Footer/>

      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}
