import { BaseService } from './base.service';
import AppConfig from '../App.config';

export class EventService extends BaseService {
    async recordImpressionEvent(target, pageName, dataSet ) {
        const promises = dataSet?.map(item => {
            const key1 = target;
            let key2 = null;
            let key3 = null;
            let data = {pageName: pageName};

            switch(target) {
                case 'work':
                    key2 = item.creatorId;
                    key3 = item.assetId;
                    break;
                case 'blog':
                    key2 = item.blogId;
                    break;
                default:
                    break;
            }

            this.recordViewEvent('impression', key1, key2, key3, data);
        });

        await Promise.all(promises);
    }
    
    async recordPageView(pageName, previousPage, user ) {
        await this.recordViewEvent('page', pageName, null, null ,{
            previousPage: previousPage,
            user: user?.getId()
        });
    }

    async recordWorksView(creatorId, assetId, previousPage, user ) {
        await this.recordViewEvent('work', creatorId, assetId, null ,{
            previousPage: previousPage,
            user: user?.getId()
        });        
    }

    async recordBlogView(blogId, previousPage, user ) {
        await this.recordViewEvent('blog', blogId, null, null ,{
            previousPage: previousPage,
            user: user?.getId()
        });
    }

    async recordViewEvent(eventType, key1, key2, key3, data) {
        const url = `${AppConfig.eventApi}/recordViewEvent`;
        
        const body = {
            eventType: eventType,
            key1: key1,
            key2: key2,
            key3: key3,
            data: data
        };

        try {
            await this.executePost(url, body, false);
        } catch (error) {
            console.log(`recordViewEvent failed: ${JSON.stringify(error)}`);
        }
    }
}