import React,{useEffect, useState} from 'react'
import Navbar from '../../components/navbar'
import workshop1 from '../../assets/images/workshop1.jpg';
import Footer from '../../components/footer';
import PricePackage from '../../components/price-package';
import {ApplicationsService, CreatorService} from '../../services';
import { useNavigate } from "react-router-dom";
import { store } from '../../state';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();
let showWait = false;
export default function BecomeCreator() {
    let [displayName, setDisplayName] = useState(null);
    let [displayNameValid, setDisplayNameValid] = useState("required");
    let [creatorStory, setCreatorStory] = useState(null);
    let [creatorStoryValid, setCreatorStoryValid] = useState(false);
    let [website, setWebsite] = useState(null);
    let [twitterAccount, setTwitterAccount] = useState(null);
    let [instagramAccount, setInstagramAccount] = useState(null);
    let [facebookAccount, setFacebookAccount] = useState(null);
    let [pricePackages, setPricePackages] = useState([]);
    let [pricePackagesLoaded, setPricePackagesLoaded] = useState(false);
    let [selectedPackage, setSelectedPackage] = useState(0);
    //let [showWait, setShowWait] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
        if(!store.state.user.loggedIn) {
            // Redirect to login if user not logged in.
            store.state.session.setRedirectTo('/become-creator'); 
            navigate('/login');
        }
        
        if(!pricePackagesLoaded) {
            appSrv.getActivePricePackages().then(result => {
                if(result) {
                    setPricePackages(result.pricePackages);
                    
                    setPricePackagesLoaded(true);
                    console.log(`user ${store.state.user.getId()} price package: ${store.state.user.getPackageId()}`);
                }
            })
        }
        
    }, []);

    const handleDisplayNameChange = async (val) => {
        setDisplayName(val);
        const avail = await creatorSrv.isNameAvailable(val);
        const valid = val.length === 0 ? "required" : avail ? "" : "not available" 
        setDisplayNameValid(valid);
    }

    const handleStoryChange = (val) => {
        setCreatorStory(val);

        if (val.length > 0) {
            setCreatorStoryValid(true);
        } else  {
            setCreatorStoryValid(false);
        }
    }

    const handleSubmit = async () => {
        toast.info('Registering new creator, please waint, this will take a few seconds.');
        showWait = true;
        const creatorId = await creatorSrv.registerCreator(store.state.user.getId(),
            displayName, 
            creatorStory
        ).then ((data) => {
            if (data === null) {
                toast.error('Creator registration failed.');
                return null;
            } else {
                toast.success('Creator registered successfully.');
                return data;
            }
        }).catch((exp) => {
            toast.error('Creator registration failed.');
        });

        if (creatorId) {
            const promises = [];
            if (website) {
                promises.push(creatorSrv.addSocialMediaLink(creatorId, 'website', website));
            }

            if (twitterAccount) {
                promises.push(creatorSrv.addSocialMediaLink(creatorId, 'twitter', twitterAccount));
            }

            if (instagramAccount) {
                promises.push(creatorSrv.addSocialMediaLink(creatorId, 'instagram', instagramAccount));
            }

            if (facebookAccount) {
                promises.push(creatorSrv.addSocialMediaLink(creatorId, 'facebook', facebookAccount));
            }

            if (promises.length > 0) {
                await Promise.all(promises).then(() => {
                    toast.success('Successfully saved links to creator social media,');
                    
                }).catch((exp) => {
                    toast.error('Failed to save links to creator social media.');
                });
            }
            
            navigate(`/creator-profile/${creatorId}`);
        }

        showWait = false;
    }

    const choosePricePackage = async (id) => {
        await appSrv.selectPricePackage(store.state.user.getId(), id);
        setSelectedPackage(id);
        store.state.user.setPackageId(id)
        navigate('#step2');
    }

  return (
    <>
    {showWait && (
    <div className="bg-overlay d-flex align-items-center">
        <h1 className="text-white text-center">Please Wait</h1>
    </div>
    )}
    <Navbar/>
    <section className="bg-half-75 d-table w-100 bg-light">
      <div className="container">
          <div className="row mt-5 align-items-center">
              <div className="col-lg-4 col-md-6">
                  <img src={workshop1} className="img-fluid" alt=""/>
              </div>

              <div className="col-lg-8 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="title-heading">
                      <h6>Become a Creator!</h6>
                      <h5 className="heading fw-bold title-dark mb-4">Start Your <br/><span className="text-gradient-primary">Journey</span></h5>
                      <p className="text-muted mb-0 para-desc">Bring your art and creative work to the world.</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section id="step1" className="section">
        <div className="pricing-header px-3 py-3 pt-md-1 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Pricing</h1>
            <p className="lead">Choose the package that best fits your need</p>
        </div>
        <div className="col-12 d-flex justify-content-center">
            {pricePackages.map((p, index) => {
                return (
                        <PricePackage id={p.id}
                        title={p.title}
                        price={p.price}
                        timeUnit={p.timeUnit}
                        priceProperties={p.priceProperties} 
                        active={selectedPackage === p.id}
                        callback={choosePricePackage}>
                        </PricePackage>
                );
            })}
        </div>
    </section>
    
  <section id="step2" className="section">
    <div className="container">
        <div className="row">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-3">Fill the form</h4>
                    <p className="text-muted mb-0 para-desc mx-auto"></p>
                </div>
            </div>
        </div>
        <div className="row justify-content-center mt-4 pt-2">
            <div className="col-lg-10">
                <div className="card p-4 rounded-md shadow">
                    <div className="row">
                        <div className="mt-4 mt-sm-0">
                            <div className="ms-md-4">
                                <form >
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <label className="form-label">
                                                Display Name 
                                                <i className="mdi mdi-vector-link display-name-tooltip mx-2" />
                                                <Tooltip anchorSelect=".display-name-tooltip" place="top">
                                                    This value is stored on chain.
                                                </Tooltip>
                                            </label>
                                            <small className="text-muted d-block">This is your unique identifying name, your moniker. Think carefully as this value cannot be changed.</small>
                                            <input 
                                                name="name" 
                                                id="first" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="streetboyyy" 
                                                onChange={(e) => handleDisplayNameChange(e.target.value)}/>
                                            <span className={"warning-text " + (displayNameValid ? "" : "hidden")}>{displayNameValid}</span>
                                        </div>
        
                                        <div className="col-12 mb-4">
                                            <label className="form-label">Your Story
                                                <i className="mdi mdi-vector-link story-tooltip mx-2" />
                                                <Tooltip anchorSelect=".story-tooltip" place="top">
                                                    This value is stored on chain.
                                                </Tooltip>
                                            </label>
                                            <small className="text-muted d-block">This is your chance to tell your unique story. What are you works about? WHat drives you? Why do you do, what you do?</small>
                                            <textarea 
                                                name="comments" 
                                                id="comments" 
                                                rows="3" 
                                                className="form-control" 
                                                placeholder="I'm a Digital Artist. Digital Art with over 3 years of experience. Experienced with all stages of the Art cycle for dynamic projects."
                                                onChange={(e) => handleStoryChange(e.target.value)}></textarea>
                                                { !creatorStoryValid && (
                                                    <span className="warning-text ">required</span>
                                                )}
                                        </div>
    
                                        <div className="col-12 mb-4">
                                            <label className="form-label">Website
                                                <i className="mdi mdi-help-circle-outline website-tooltip mx-2" />
                                                <Tooltip anchorSelect=".website-tooltip" place="top">
                                                    optional
                                                </Tooltip>
                                            </label>
                                            <small className="text-muted d-block">Display a link to your website on your creator profile.</small>
                                            <div className="form-icon">
                                                <input 
                                                    name="url" 
                                                    id="website-url" 
                                                    type="url" 
                                                    className="form-control" 
                                                    placeholder="https://createdbyme.io"
                                                    onChange={(e) => setWebsite(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <label className="form-label d-block">Twitter Account
                                                <i className="mdi mdi-help-circle-outline twitter-account-tooltip mx-2" />
                                                <Tooltip anchorSelect=".twitter-account-tooltip" place="top">
                                                    optional
                                                </Tooltip></label>
                                            <small className="text-muted d-block">Display a link to your twitter account on your creator profile.</small>
                                            <div className="form-icon mt-3">
                                                <input 
                                                    name="url" 
                                                    id="twitter-url" 
                                                    type="url" 
                                                    className="form-control" 
                                                    placeholder="https://twitter.com/streetboyyy"
                                                    onChange={(e) => setTwitterAccount(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <label className="form-label d-block">Instagram Account
                                                <i className="mdi mdi-help-circle-outline instagram-account-tooltip mx-2" />
                                                <Tooltip anchorSelect=".instagram-account-tooltip" place="top">
                                                    optional
                                                </Tooltip></label>
                                            <small className="text-muted d-block">Display a link to your instagram account on your creator profile.</small>
                                            <div className="form-icon mt-3">
                                                <input 
                                                    name="url" 
                                                    id="instagram-url" 
                                                    type="url"
                                                    className="form-control" 
                                                    placeholder="https://instagram.com/streetboyyy"
                                                    onChange={(e) => setInstagramAccount(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <label className="form-label d-block">Facebook Account
                                                <i className="mdi mdi-help-circle-outline facebook-account-tooltip mx-2" />
                                                <Tooltip anchorSelect=".facebook-account-tooltip" place="top">
                                                    optional
                                                </Tooltip></label>
                                            <small className="text-muted d-block">Display a link to your facebook account on your creator profile.</small>
                                            <div className="form-icon mt-3">
                                                <input 
                                                    name="url" 
                                                    id="facebook-url" 
                                                    type="url" 
                                                    className="form-control" 
                                                    placeholder="https://facebook.com/streetboyyy"
                                                    onChange={(e) => setFacebookAccount(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button 
                                                type="button" 
                                                className="btn btn-primary rounded-md"
                                                onClick={handleSubmit}
                                                disabled={displayNameValid !== '' || !creatorStoryValid || selectedPackage < 1}
                                            >Create Your Account</button>
                                            <div className={"warning-text " + (selectedPackage < 1 ? "" : "hidden")}>Select a price package</div>
                                            <div className={"warning-text " + (displayNameValid ? "" : "hidden")}>Enter a display name</div>
                                            <div className={"warning-text " + (!creatorStoryValid ? "" : "hidden")}>Enter your story</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <Footer/>   
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />           
    </>
  )
}
