import React from 'react'

export default function Badge({
    title, 
    deleteCallback=null
}) {

    return (
        <div className="badge-wrapper m-1 mt-1 mb-1">
            <span className="badge bg-secondary p-3">
                {title}
                {deleteCallback !== null && (
                <i 
                    className="uil uil-times-circle fs-5 badge-cancel"
                    onClick={deleteCallback}></i>
                )}
            </span>
        </div>
    );
}