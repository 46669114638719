import { AppStorage } from "./storage";

class UserState extends AppStorage {
    loggedIn = false;

    constructor () {
        super("user-state");
        this.load();

        if (!this.data.id ) {
            this.logout();
        } else if (this.data.id !== -1) {
            this.loggedIn = true;
        }
    }

    login(val) {
        this.data.creatorId = val.creatorId;
        this.data.emailAddress = val.emailAddress;
        this.data.id = val.id;
        this.data.linkedWallet = val.linkedWallet;
        this.data.sessionId = val.sessionId;
        this.data.packageId = val.packageId;

        this.loggedIn = true;

        this.store();
    }

    logout() {
        this.data = {
            "creatorId": "",
            "emailAddress": "",
            "id": -1,
            "linkedWallet": null,
            "sessionId": null,
            "packageId": -1,
          }

          this.loggedIn = false;

          this.store();
    }

    setCreatorId(val) {
        this.data.creatorId = val;
        this.store();
    }

    getCreatorId() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        }

        return this.data.creatorId;
    }

    setEmailAddress(val) {
        this.data.emailAddress = val;
        this.store();
    }

    getEmailAddress() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        }

        return this.data.emailAddress;
    }

    setId(val) {
        this.data.id = val;
        this.store();
    }

    getId() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        }

        return this.data.id;
    }

    setLinkedWallet(val) {
        this.data.linkedWallet = val;
        this.store();
    }

    getLinkedWallet() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        } 

        return this.data.linkedWallet;
    }

    setSessionId(val) {
        this.data.sessionId = val;
        this.store();
    }

    getSessionId() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        } 

        return this.data.sessionId;
    }

    setPackageId(val) {
        this.data.packageId = val;
        this.store();
    }

    getPackageId() {
        if (!this.data.id || this.data.id === -1) {
            this.load();
        } 

        return this.data.packageId;
    }
}

const appUserState = new UserState();
export default appUserState; 