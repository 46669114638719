import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogCard({
    titleImage,
    topic,
    title,
    displayName,
    creatorId,
    friendlyName,
    updatedDate,
}) {
  return (

    <div className="card blog blog-primary shadow rounded-md overflow-hidden">
        <div className="position-relative">
            <img src={titleImage} className="img-fluid rounded-md" alt=""/>
            <div className="position-absolute top-0 end-0 m-3">
                <span className="like-icon shadow-sm">
                    <Link to="#" className="text-muted icon">
                        <i className="mdi mdi-18px mdi-heart mb-0"></i>
                    </Link>
                </span>
            </div>
        </div>
        <div className="card-body position-relative p-4">
            <Link to="" className="badge tag gradient rounded-md fw-bold">{topic}</Link>

            <ul className="list-unstyled mt-2">
                <li className="list-inline-item text-muted small me-3">
                    <i className="uil uil-calendar-alt text-dark h6 me-1"></i>
                    {updatedDate}
                </li>
            </ul>
            <Link to={`/blog-detail/${displayName}/${friendlyName}`} className="text-dark title h5 mt-3">{title}</Link>
            
            <div className="mt-3 d-flex justify-content-between align-items-center">
                <Link to={`/blog-detail/${displayName}/${friendlyName}`} className="btn btn-link text-muted">Read more<i className="uil uil-arrow-right fs-5"></i></Link>
                <span className="text-muted fs-6">by <Link to={`/creator-profile/${displayName}`} className="link">{displayName}</Link></span>
            </div>
        </div>
    </div>

  )
}
